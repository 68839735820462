import { Action, createReducer, on } from "@ngrx/store";
import { Categories, CategoryState, initialState } from "./category.state";
import { categoryActions } from "@store";
import { TranslationDto } from "@api";

const categoryReducer = createReducer(
  initialState,

  on(
    categoryActions.getCategories,
    categoryActions.createCategory,
    categoryActions.updateCategory,
    categoryActions.deleteCategory,
    categoryActions.createCategoryTranslation,
    categoryActions.updateCategoryTranslation,
    categoryActions.deleteCategoryTranslation,
    state => ({ ...state, loading: true }),
  ),

  on(categoryActions.getCategoriesSuccess, (state, { categories }) => ({
    ...state,
    categories: categories.reduce((result, category) => {
      result[category.id!] = category;
      return result;
    }, {} as Categories),
    loading: false,
  })),

  on(categoryActions.createCategorySuccess, categoryActions.updateCategorySuccess, (state, { category }) => ({
    ...state,
    categories: {
      ...state.categories,
      [category.id!]: category,
    },
    loading: false,
  })),

  on(categoryActions.deleteCategorySuccess, (state, { id }) => ({
    ...state,
    categories: Object.values(state.categories).reduce((result, category) => {
      if (category.id !== id) result[category.id!] = category;
      return result;
    }, {} as Categories),
    loading: false,
  })),

  on(categoryActions.getCategoryTranslationsSuccess, (state, { categoryId, translations }) => ({
    ...state,
    translations: {
      ...state.translations,
      [categoryId]: translations.reduce((result, translation) => {
        result[translation.id] = translation;
        return result;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(
    categoryActions.createCategoryTranslationSuccess,
    categoryActions.updateCategoryTranslationSuccess,
    (state, { categoryId, translation }) => ({
      ...state,
      translations: {
        ...state.translations,
        [categoryId]: {
          ...(state.translations[categoryId] || {}),
          [translation.id!]: translation,
        },
      },
      loading: false,
    })),

  on(categoryActions.deleteCategoryTranslationSuccess, (state, { categoryId, translation: deleted }) => ({
    ...state,
    translations: {
      ...state.translations,
      [categoryId]: Object.values(state.translations[categoryId]).reduce((translations, translation) => {
        if (translation.id !== deleted.id) translations[translation.id!] = translation;
        return translations;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(
    categoryActions.getCategoriesFailure,
    categoryActions.createCategoryFailure,
    categoryActions.updateCategoryFailure,
    categoryActions.deleteCategoryFailure,
    categoryActions.getCategoryTranslationsFailure,
    categoryActions.createCategoryTranslationFailure,
    categoryActions.updateCategoryTranslationFailure,
    categoryActions.deleteCategoryTranslationFailure,
    state => ({ ...state, loading: false }),
  ),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: CategoryState | undefined, action: Action) => categoryReducer(state, action);
