import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertData } from "./alert-data.interface";

@Component({
  selector: "admin-alert",
  templateUrl: "./alert.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: AlertData) {}
}
