import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Storage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { EstablishmentsService, MenusService } from "@api";
import { DEFAULT_ERROR_MESSAGE } from "@constants";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState, fromApp, menuActions } from "@store";
import { getHttpErrorMessage } from "@utils";
import { Observable, catchError, filter, map, mergeMap, of, tap, withLatestFrom } from "rxjs";

@Injectable()
export class MenuEffects {
  public getEstablishmentMenus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.getEstablishmentMenus),
      mergeMap(({ establishmentId }) =>
        this.establishmentService.establishmentV2ControllerGetEstablishmentMenus({ establishmentId }).pipe(
          map(menus => menuActions.getEstablishmentMenusSuccess({ menus })),
          catchError((error: HttpErrorResponse) => of(menuActions.getEstablishmentMenusFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public reorderEstablishmentMenus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.reorderEstablishmentMenus),
      mergeMap(({ establishmentId, reorderMenusDto }) =>
        this.establishmentService.establishmentV2ControllerReorderEstablishmentMenus({ establishmentId, reorderMenusDto }).pipe(
          map(menus => menuActions.reorderEstablishmentMenusSuccess({ menus })),
          catchError((error: HttpErrorResponse) => of(menuActions.getEstablishmentMenusFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public getMenu$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.getMenu),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerGetMenu({ menuId }).pipe(
          map(menu => menuActions.getMenuSuccess({ menu })),
          catchError((error: HttpErrorResponse) => of(menuActions.getMenuFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public createMenu$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.createMenu),
      mergeMap(({ createMenuDto }) =>
        this.menuService.menuV2ControllerCreateMenu({ createMenuDto }).pipe(
          map(menu => menuActions.createMenuSuccess({ menu })),
          catchError((error: HttpErrorResponse) => of(menuActions.createMenuFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public createMenuSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.createMenuSuccess),
      withLatestFrom(this.store.select(fromApp.selectLanguage)),
      mergeMap(([{ menu }, language]) =>
        this.menuService.menuV2ControllerCreateMenuTranslations({
          menuId: menu.id!,
          createTranslationDto: { menuId: menu.id, value: menu.name, language },
        }).pipe(
          map(translation => menuActions.createMenuTranslationSuccess({ menuId: menu.id!, translation })),
          catchError((error: HttpErrorResponse) => of(menuActions.createMenuTranslationFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public editMenu$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.editMenu),
      mergeMap(({ menuId, updateMenuDto }) =>
        this.menuService.menuV2ControllerUpdateMenu({ menuId, updateMenuDto }).pipe(
          map(menu => menuActions.editMenuSuccess({ menu })),
          catchError((error: HttpErrorResponse) => of(menuActions.editMenuFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public deleteMenu$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.deleteMenu),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerDeleteMenu({ menuId }).pipe(
          map(() => menuActions.deleteMenuSuccess({ id: menuId })),
          catchError((error: HttpErrorResponse) => of(menuActions.deleteMenuFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public getMenuTranslations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.getMenuTranslations),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerGetMenuTranslations({ menuId }).pipe(
          map(translations => menuActions.getMenuTranslationsSuccess({ menuId, translations })),
          catchError((error: HttpErrorResponse) =>
            of(menuActions.getMenuTranslationsFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public createMenuTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.createMenuTranslation),
      mergeMap(({ menuId, createTranslationDto }) =>
        this.menuService.menuV2ControllerCreateMenuTranslations({ menuId, createTranslationDto }).pipe(
          map(translation => menuActions.createMenuTranslationSuccess({ menuId, translation })),
          catchError((error: HttpErrorResponse) => of(menuActions.createMenuTranslationFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public updateMenuTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.updateMenuTranslation),
      mergeMap(({ menuId, translationId, createTranslationDto }) =>
        this.menuService.menuV2ControllerUpdateMenuTranslations({ menuId, translationId, createTranslationDto }).pipe(
          map(translation => menuActions.updateMenuTranslationSuccess({ menuId, translation })),
          catchError((error: HttpErrorResponse) => of(menuActions.updateMenuTranslationFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public deleteMenuTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.deleteMenuTranslation),
      mergeMap(({ menuId, translationId }) =>
        this.menuService.menuV2ControllerDeleteMenuTranslations({ menuId, translationId }).pipe(
          map(translation => menuActions.deleteMenuTranslationSuccess({ menuId, translation })),
          catchError((error: HttpErrorResponse) => of(menuActions.deleteMenuTranslationFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public getMenuCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.getMenuCategories),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerGetMenuCategories({ menuId }).pipe(
          map(categories => menuActions.getMenuCategoriesSuccess({ menuId, categories })),
          catchError((error: HttpErrorResponse) => of(menuActions.getMenuCategoriesFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public createMenuCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.createMenuCategory),
      mergeMap(({ menuId, categoryId, createMenuCategoryDto }) =>
        this.menuService.menuV2ControllerCreateMenuCategory({ menuId, categoryId, createMenuCategoryDto }).pipe(
          map(menuCategory => menuActions.createMenuCategorySuccess({ menuId, menuCategory })),
          catchError((error: HttpErrorResponse) => of(menuActions.createMenuCategoryFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public updateMenuCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.updateMenuCategory),
      mergeMap(({ menuId, categoryId, updateMenuCategoryDto, callback }) =>
        this.menuService.menuV2ControllerUpdateMenuCategory({ menuId, categoryId, updateMenuCategoryDto }).pipe(
          map(menuCategory => menuActions.updateMenuCategorySuccess({ menuId, menuCategory, callback })),
          catchError((error: HttpErrorResponse) => of(menuActions.updateMenuCategoryFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public updateMenuCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.updateMenuCategories),
      mergeMap(({ menuId, updateMenuContentDto }) =>
        this.menuService.menuV2ControllerUpdateMenuCategories({ menuId, updateMenuContentDto }).pipe(
          map(categories => menuActions.updateMenuCategoriesSuccess({ menuId, categories })),
          catchError((error: HttpErrorResponse) => of(menuActions.updateMenuCategoriesFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public deleteMenuCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.deleteMenuCategory),
      mergeMap(({ menuId, categoryId, callback }) =>
        this.menuService.menuV2ControllerDeleteMenuCategory({ menuId, categoryId }).pipe(
          map(() => menuActions.deleteMenuCategorySuccess({ menuId, categoryId, callback })),
          catchError((error: HttpErrorResponse) => of(menuActions.deleteMenuCategoryFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public deleteMenuCategorySuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        menuActions.updateMenuCategorySuccess,
        menuActions.deleteMenuCategorySuccess,
      ),
      tap(({ callback }) => callback()),
    ), { dispatch: false },
  );

  public getMenuProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.getMenuProducts),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerGetMenuProducts({ menuId }).pipe(
          map(products => menuActions.getMenuProductsSuccess({ products })),
          catchError((error: HttpErrorResponse) => of(menuActions.getMenuProductsFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public createMenuProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.createMenuProduct),
      mergeMap(({ menuId, categoryId, productId, createMenuCategoryProductDto, callback }) =>
        this.menuService.menuV2ControllerCreateMenuCategoryProduct({ menuId, categoryId, productId, createMenuCategoryProductDto }).pipe(
          map(product => menuActions.createMenuProductSuccess({ product, callback })),
          catchError((error: HttpErrorResponse) => of(menuActions.createMenuProductFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public updateMenuProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.updateMenuProduct),
      mergeMap(({ menuId, categoryId, productId, updateMenuCategoryProductDto }) =>
        this.menuService.menuV2ControllerUpdateMenuCategoryProduct({ menuId, categoryId, productId, updateMenuCategoryProductDto }).pipe(
          map(product => menuActions.updateMenuProductSuccess({ product })),
          catchError((error: HttpErrorResponse) => of(menuActions.updateMenuProductFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public updateMenuProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.updateMenuProducts),
      mergeMap(({ menuId, categoryId, updateMenuCategoryContentDto }) =>
        this.menuService.menuV2ControllerUpdateMenuCategoryProducts({ menuId, categoryId, updateMenuCategoryContentDto }).pipe(
          map(products => menuActions.updateMenuProductsSuccess({ products })),
          catchError((error: HttpErrorResponse) => of(menuActions.updateMenuProductsFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public deleteMenuProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.deleteMenuProduct),
      mergeMap(({ menuId, categoryId, productId, callback }) =>
        this.menuService.menuV2ControllerDeleteMenuCategoryProduct({ menuId, categoryId, productId }).pipe(
          map(() => menuActions.deleteMenuProductSuccess({ menuId, categoryId, productId, callback })),
          catchError((error: HttpErrorResponse) => of(menuActions.deleteMenuProductFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public getMenuTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.getMenuTimetables),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerGetMenuTimetables({ menuId }).pipe(
          map(timetables => menuActions.getMenuTimetablesSuccess({ menuId, timetables })),
          catchError((error: HttpErrorResponse) => of(menuActions.getMenuTimetablesFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public upsertMenuTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.upsertMenuTimetables),
      mergeMap(({ menuId, createEstablishmentTimetables }) =>
        this.menuService.menuV2ControllerCreateOrUpdateMenuTimetables({ menuId, createEstablishmentTimetables }).pipe(
          map(timetables => menuActions.upsertMenuTimetablesSuccess({ menuId, timetables })),
          catchError((error: HttpErrorResponse) => of(menuActions.upsertMenuTimetablesFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public deleteMenuTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(menuActions.deleteMenuTimetables),
      mergeMap(({ menuId }) =>
        this.menuService.menuV2ControllerDeleteMenuTimetables({ menuId }).pipe(
          map(() => menuActions.deleteMenuTimetablesSuccess({ menuId })),
          catchError((error: HttpErrorResponse) => of(menuActions.deleteMenuTimetablesFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public executeCallback$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        menuActions.createMenuProductSuccess,
        menuActions.deleteMenuProductSuccess,
      ),
      filter(({ callback }) => !!callback),
      tap(({ callback }) => callback!()),
    ), { dispatch: false },
  );

  public showError$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        menuActions.getEstablishmentMenusFailure,
        menuActions.getMenuFailure,
        menuActions.createMenuFailure,
        menuActions.createMenuTranslationFailure,
        menuActions.editMenuFailure,
        menuActions.deleteMenuFailure,
        menuActions.deleteMenuTranslationFailure,
        menuActions.getMenuProductsFailure,
      ),
      tap(({ reason }) => this.snackBar.open(this.translateService.instant(reason || DEFAULT_ERROR_MESSAGE))),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly establishmentService: EstablishmentsService,
    private readonly menuService: MenusService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly storage: Storage = inject(Storage),
  ) {}
}
