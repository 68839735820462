import { ChangeDetectionStrategy, Component, HostListener, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatDrawerMode } from "@angular/material/sidenav";
import { DomSanitizer } from "@angular/platform-browser";
import { ALLERGENS, ICONS } from "@constants";
import { Store } from "@ngrx/store";
import { appActions, fromApp, RootState } from "@store";
import { combineLatest, first, Observable } from "rxjs";

@Component({
  selector: "admin-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public readonly sidenavMode$: Observable<MatDrawerMode> = this.store.select(fromApp.selectSidenavMode);

  private readonly sidenavOpened$: Observable<boolean> = this.store.select(fromApp.selectSidenavOpened);
  private readonly sidenavFolded$: Observable<boolean> = this.store.select(fromApp.selectSidenavFolded);

  constructor(
    private readonly store: Store<RootState>,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    ICONS.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon}.svg`));
    });
    ALLERGENS.forEach(allergen => {
      this.matIconRegistry.addSvgIcon(allergen, this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/allergens/${allergen}.svg`));
    });
  }

  @HostListener("window:resize", ["$event.target"])
  protected onResize(window: Window): void {
    combineLatest([this.sidenavMode$, this.sidenavFolded$, this.sidenavOpened$]).pipe(first()).subscribe(([mode, folded, opened]) => {
      const isMobile = window.innerWidth < 600;
      if (mode === "side" && isMobile) {
        this.store.dispatch(appActions.toggleSidenavMode({ mode: "over" }));
        if (folded) this.store.dispatch(appActions.toggleSidenavFolded());
      } else if (mode === "over" && !isMobile) {
        this.store.dispatch(appActions.toggleSidenavMode({ mode: "side" }));
        if (!opened) this.store.dispatch(appActions.toggleSidenav());
      }
    });
  }

  public ngOnInit(): void {
    this.onResize(window);
  }
}
