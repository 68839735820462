<h2 mat-dialog-title>{{ "auth.upgradeRequired.title" | translate }}</h2>

<div mat-dialog-content>
    <p [innerHTML]="'auth.upgradeRequired.message' | translate : data"></p>
</div>

<div align="end" mat-dialog-actions>
    <button color="primary" mat-raised-button [disabled]="loading$ | async" (click)="resendDoi()">
        {{ "auth.upgradeRequired.resendDoi" | translate }}
    </button>

    <button color="primary" mat-dialog-close mat-stroked-button>
        {{ "auth.upgradeRequired.close" | translate }}
    </button>
</div>
