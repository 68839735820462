import { Action, createReducer, on } from "@ngrx/store";
import { appActions } from "@store";
import { AppState, initialState } from "./app.state";

const appReducer = createReducer(
  initialState,

  on(appActions.changeLanguage, (state, { language }) => ({ ...state, language })),

  on(appActions.showLoader, state => ({ ...state, loading: true })),

  on(appActions.hideLoader, state => ({ ...state, loading: false })),

  on(appActions.toggleSidenav, (state, { opened }) => ({
    ...state,
    sidenavOpened: typeof opened !== "undefined" ? opened : !state.sidenavOpened,
  })),

  on(appActions.toggleSidenavMode, (state, { mode }) => ({
    ...state,
    sidenavMode: mode,
  })),

  on(appActions.toggleSidenavFolded, state => ({ ...state, sidenavFolded: !state.sidenavFolded })),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: AppState | undefined, action: Action) => appReducer(state, action);
