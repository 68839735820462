/* eslint-disable @typescript-eslint/naming-convention */
import { EstablishmentDto } from "@api";

export * from "./environments/environment";
export * from "./features";
export * from "./icons";
export * from "./injection-tokens";
export * from "./timetable-options";

export enum AppRoutes {
  ConfirmEmail = "confirm-email",
  Contact = "contact",
  Customization = "customization",
  Dashboard = "dashboard",
  ForgotPassword = "forgot-password",
  General = "general",
  Google = "google",
  Information = "information",
  Login = "login",
  Main = "",
  Menus = "menus",
  Personal = "personal-info",
  Register = "register",
  ResetPassword = "reset-password",
  Settings = "settings",
  Social = "social",
  Subscription = "subscription",
}

export enum RouteParams {
  MenuId = "menuId",
}

export enum QueryParams {
  OverwriteLanguage = "overwrite_language",
  Token = "token"
}

export const DEFAULT_LANGUAGE = EstablishmentDto.EnabledLanguagesEnum.Spanish;

export const DEFAULT_ERROR_MESSAGE: string = "SOMETHING_WENT_WRONG";

export const NO_INTERCEPTOR_ROUTES: AppRoutes[] = [AppRoutes.Login];

export const NO_INTERCEPTOR_REQUESTS: string[] = ["refresh"];

export const MAXIMUM_ESTABLISHMENT_IMAGE_FILE_SIZE: number = 1024000;

export const ALERT_DIALOG_WIDTH: string = "450px";
export const ALERT_DIALOG_MAX_WIDTH: string = "90vw";

export const DISABLED_LANGUAGES: EstablishmentDto.EnabledLanguagesEnum[] = [
  EstablishmentDto.EnabledLanguagesEnum.French,
  EstablishmentDto.EnabledLanguagesEnum.German,
  EstablishmentDto.EnabledLanguagesEnum.Italian,
  EstablishmentDto.EnabledLanguagesEnum.Portuguese,
];

export const MAP_LANGUAGE_ISO_TO_ENUM: { [key: string]: EstablishmentDto.EnabledLanguagesEnum } = {
  ca: EstablishmentDto.EnabledLanguagesEnum.Catalan,
  de: EstablishmentDto.EnabledLanguagesEnum.German,
  en: EstablishmentDto.EnabledLanguagesEnum.English,
  es: EstablishmentDto.EnabledLanguagesEnum.Spanish,
  fr: EstablishmentDto.EnabledLanguagesEnum.French,
  it: EstablishmentDto.EnabledLanguagesEnum.Italian,
  pt: EstablishmentDto.EnabledLanguagesEnum.Portuguese,
};

export const MAP_LANGUAGE_ENUM_TO_ISO: { [key in EstablishmentDto.EnabledLanguagesEnum]: string } = {
  CATALAN: "ca",
  ENGLISH: "en",
  FRENCH: "fr",
  GERMAN: "de",
  ITALIAN: "it",
  PORTUGUESE: "pt",
  SPANISH: "es",
};

// ISO 4217 currency codes
export const MAP_CURRENCY_TO_ISO: { [key in EstablishmentDto.CurrencyEnum]: string } = {
  BRITISHPOUND: "GBP",
  EURO: "EUR",
  UNITEDSTATESDOLLAR: "USD",
};

export enum Patterns {
  Email = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]{1,10}$"
}
