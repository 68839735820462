import { Action, createReducer, on } from "@ngrx/store";
import { MenuCategoryProducts, MenuState, Menus, initialState } from "./menu.state";
import { menuActions } from "@store";
import { MenuCategoryProductDto, TranslationDto } from "@api";

const menuReducer = createReducer(
  initialState,

  on(
    menuActions.getEstablishmentMenus,
    menuActions.getMenu,
    menuActions.getMenuTranslations,
    menuActions.createMenuTranslation,
    menuActions.updateMenuTranslation,
    menuActions.deleteMenuTranslation,
    menuActions.getMenuCategories,
    menuActions.getMenuProducts,
    menuActions.createMenu,
    menuActions.editMenu,
    menuActions.deleteMenu,
    menuActions.createMenuCategory,
    menuActions.updateMenuCategory,
    menuActions.deleteMenuCategory,
    menuActions.createMenuProduct,
    menuActions.updateMenuProduct,
    menuActions.deleteMenuProduct,
    menuActions.getMenuTimetables,
    menuActions.upsertMenuTimetables,
    menuActions.deleteMenuTimetables,
    state => ({ ...state, loading: true }),
  ),

  on(
    menuActions.getEstablishmentMenusSuccess,
    menuActions.reorderEstablishmentMenusSuccess,
    (state, { menus }) => ({
      ...state,
      menus: menus.reduce((result, menu) => {
        result[menu.id!] = menu;
        return result;
      }, {} as Menus),
      loading: false,
    }),
  ),

  on(menuActions.reorderEstablishmentMenus, (state, { reorderMenusDto }) => ({
    ...state,
    menus: Object.values(state.menus).reduce((result, menu) => {
      const item = reorderMenusDto.menus.find(m => m.menuId === menu.id);

      result[menu.id!] = { ...menu, order: item!.order };
      return result;
    }, {} as Menus),
    loading: true,
  })),

  on(
    menuActions.getMenuSuccess,
    menuActions.createMenuSuccess,
    menuActions.editMenuSuccess,
    (state, { menu }) => ({
      ...state,
      menus: {
        ...state.menus,
        [menu.id!]: menu,
      },
      loading: false,
    }),
  ),

  on(menuActions.deleteMenuSuccess, (state, { id }) => ({
    ...state,
    menus: Object.values(state.menus).reduce((result, menu) => {
      if (menu.id !== id) result[menu.id!] = menu;
      return result;
    }, {} as Menus),
    loading: false,
  })),

  on(menuActions.getMenuTranslationsSuccess, (state, { menuId, translations }) => ({
    ...state,
    translations: {
      ...state.translations,
      [menuId]: translations.reduce((result, translation) => {
        result[translation.id] = translation;
        return result;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(menuActions.createMenuTranslationSuccess, menuActions.updateMenuTranslationSuccess, (state, { menuId, translation }) => ({
    ...state,
    translations: {
      ...state.translations,
      [menuId]: {
        ...(state.translations[menuId] || {}),
        [translation.id!]: translation,
      },
    },
    loading: false,
  })),

  on(menuActions.deleteMenuTranslationSuccess, (state, { menuId, translation: deleted }) => ({
    ...state,
    translations: {
      ...state.translations,
      [menuId]: Object.values(state.translations[menuId]).reduce((translations, translation) => {
        if (translation.id !== deleted.id) translations[translation.id!] = translation;
        return translations;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(menuActions.updateMenuCategories, (state, { menuId, updateMenuContentDto: { menuCategories } }) => ({
    ...state,
    categories: {
      ...state.categories,
      [menuId]: menuCategories.map(mc => {
        const menuCategory = state.categories[menuId].find(mc2 => mc2.id === mc.menuCategoryId);
        return {
          ...menuCategory,
          menuId: menuCategory!.menuId!,
          categoryId: menuCategory!.categoryId!,
          ...mc,
        };
      }),
    },
    loading: true,
  })),

  on(
    menuActions.getMenuCategoriesSuccess,
    menuActions.updateMenuCategoriesSuccess,
    (state, { menuId, categories }) => ({
      ...state,
      categories: {
        ...state.categories,
        [menuId]: categories,
      },
      selectedMenuCategory: {
        ...state.selectedMenuCategory,
        [menuId]: state.selectedMenuCategory[menuId] ? state.selectedMenuCategory[menuId] : categories.length ? categories[0].id! : 0,
      },
      loading: false,
    })),

  on(menuActions.createMenuCategorySuccess, (state, { menuId, menuCategory }) => ({
    ...state,
    categories: {
      ...state.categories,
      [menuId]: [...(state.categories[menuId] || []), menuCategory],
    },
    selectedMenuCategory: {
      ...state.selectedMenuCategory,
      [menuId]: menuCategory.id!,
    },
    loading: false,
  })),

  on(menuActions.updateMenuCategorySuccess, (state, { menuId, menuCategory }) => {
    const index = state.categories[menuId].findIndex(mc => mc.id === menuCategory.id);
    const menuCategories = [...state.categories[menuId]];
    menuCategories[index] = menuCategory;

    const firstMenuCategory: number = state.categories[menuCategory.menuId]?.filter(mc => mc.active)[1]?.id ?? 0;

    return {
      ...state,
      categories: {
        ...state.categories,
        [menuId]: menuCategories,
      },
      selectedMenuCategory: {
        ...state.selectedMenuCategory,
        [menuId]: menuCategory.active || state.showInactiveCategories ? menuCategory.id! : firstMenuCategory,
      },
      loading: false,
    };
  }),

  on(menuActions.deleteMenuCategorySuccess, (state, { menuId, categoryId }) => ({
    ...state,
    categories: {
      ...state.categories,
      [menuId]: [...(state.categories[menuId] || [])].filter(menuCategory => menuCategory.categoryId !== categoryId),
    },
    selectedMenuCategory: {
      ...state.selectedMenuCategory,
      [menuId]: state.categories[menuId]?.filter(mc => mc.active)[1]?.id ?? 0,
    },
    loading: false,
  })),

  on(menuActions.selectMenuCategory, (state, { menuId, menuCategoryId }) => ({
    ...state,
    selectedMenuCategory: {
      ...state.selectedMenuCategory,
      [menuId]: menuCategoryId,
    },
  })),

  on(menuActions.toggleShowInactiveCategories, (state, { menuId }) => {
    const currentActive = state.categories[menuId].find(menuCategory => menuCategory.id === state.selectedMenuCategory[menuId]);
    let selectedMenuCategory: number = state.selectedMenuCategory[menuId];

    if (!currentActive?.active && state.showInactiveCategories) {
      selectedMenuCategory = state.categories[menuId].length ? state.categories[menuId][0].id! : 0;
    }

    return {
      ...state,
      showInactiveCategories: !state.showInactiveCategories,
      selectedMenuCategory: {
        ...state.selectedMenuCategory,
        [menuId]: selectedMenuCategory,
      },
    };
  }),

  on(
    menuActions.getMenuProductsSuccess,
    menuActions.updateMenuProductsSuccess,
    (state, { products }) => ({
      ...state,
      products: {
        ...state.products,
        ...products.reduce((result, product) => {
          result[product.id!] = product;
          return result;
        }, {} as MenuCategoryProducts),
      },
      loading: false,
    }),
  ),

  on(menuActions.createMenuProductSuccess, (state, { product }) => ({
    ...state,
    products: {
      ...state.products,
      [product.id!]: product,
    },
    loading: false,
  })),

  on(menuActions.updateMenuProductSuccess, (state, { product }) => ({
    ...state,
    products: {
      ...state.products,
      [product.id!]: product,
    },
    loading: false,
  })),

  on(
    menuActions.updateMenuProducts,
    (state, { updateMenuCategoryContentDto }) => ({
      ...state,
      products: Object.values(state.products).reduce((products, product) => {
        const updated = updateMenuCategoryContentDto.menuCategoryProducts.find(mcp => mcp.menuCategoryProductId === product.id);
        products[product.id] = { ...product, ...updated };

        return products;
      }, {} as MenuCategoryProducts),
      loading: false,
    })),

  on(menuActions.deleteMenuProductSuccess, (state, { productId }) => ({
    ...state,
    products: Object.values(state.products).reduce((result: MenuCategoryProducts, product: MenuCategoryProductDto) => {
      if (product.productId !== productId) result[product.id!] = product;
      return result;
    }, {} as MenuCategoryProducts),
    loading: false,
  })),

  on(
    menuActions.getMenuTimetablesSuccess,
    menuActions.upsertMenuTimetablesSuccess,
    (state, { menuId, timetables }) => ({
      ...state,
      timetables: {
        ...state.timetables,
        [menuId]: timetables,
      },
      loading: false,
    }),
  ),

  on(menuActions.deleteMenuTimetablesSuccess, (state, { menuId }) => ({
    ...state,
    timetables: {
      ...state.timetables,
      [menuId]: [],
    },
    loading: false,
  })),

  on(
    menuActions.getEstablishmentMenusFailure,
    menuActions.getMenuFailure,
    menuActions.getMenuTranslationsFailure,
    menuActions.getMenuCategoriesFailure,
    menuActions.getMenuProductsFailure,
    menuActions.createMenuFailure,
    menuActions.editMenuFailure,
    menuActions.deleteMenuFailure,
    menuActions.createMenuCategoryFailure,
    menuActions.updateMenuCategoryFailure,
    menuActions.updateMenuCategoriesFailure,
    menuActions.deleteMenuCategoryFailure,
    menuActions.createMenuProductFailure,
    menuActions.updateMenuProductFailure,
    menuActions.updateMenuProductsFailure,
    menuActions.deleteMenuProductFailure,
    menuActions.getMenuTimetablesFailure,
    menuActions.upsertMenuTimetablesFailure,
    menuActions.deleteMenuTimetablesFailure,
    state => ({ ...state, loading: false }),
  ),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: MenuState | undefined, action: Action) => menuReducer(state, action);
