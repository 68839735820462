import { CategoryDto, TranslationDto } from "@api";

export const CATEGORY_FEATURE_KEY = "category";

export interface Categories {
  [categoryId: number]: CategoryDto;
}

export interface Translations {
  [menuId: number]: Record<number, TranslationDto>;
}

export interface CategoryState {
  categories: Categories;
  translations: Translations;
  loading: boolean;
}

export const initialState: CategoryState = {
  categories: {},
  translations: {},
  loading: false,
};

export const persistedKeys: (keyof CategoryState)[] = ["translations"];
