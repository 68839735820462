<h2 mat-dialog-title>{{ "components.createEstablishment.title" | translate }}</h2>

<div mat-dialog-content>
    <p [innerHTML]="'components.createEstablishment.description' | translate"></p>

    <div>
        <ng-lottie width="300px" [options]="{ path: 'assets/lottie/building.json' }"></ng-lottie>
    </div>

    <p [innerHTML]="'components.createEstablishment.description2' | translate"></p>

    <mat-form-field required (keyup.enter)="formControl.valid && save()">
        <mat-label>{{ "establishmentInformation.createNewLabel" | translate }}</mat-label>
        <input matInput required [formControl]="formControl" [maxlength]="50" />
    </mat-form-field>
</div>

<div align="end" mat-dialog-actions>
    <button color="primary" mat-raised-button [disabled]="loading$ | async" (click)="formControl.valid && save()">
        {{ "buttons.continue" | translate }}
    </button>
</div>
