/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@api";
import { Store } from "@ngrx/store";
import { authActions, fromAuth, RootState } from "@store";
import { combineLatest, Observable, throwError } from "rxjs";
import { catchError, first, mergeMap, switchMap } from "rxjs/operators";
import { NO_INTERCEPTOR_REQUESTS, NO_INTERCEPTOR_ROUTES } from "@constants";

@Injectable({ providedIn: "root" })
export class AuthInterceptor implements HttpInterceptor {
  private readonly accessToken$: Observable<string> = this.store.select(fromAuth.selectAccessToken);
  private readonly refreshToken$: Observable<string> = this.store.select(fromAuth.selectRefreshToken);

  constructor(private readonly store: Store<RootState>, private readonly authService: AuthService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([this.accessToken$, this.refreshToken$]).pipe(
      first(),
      switchMap(([accessToken, refreshToken]) => {
        if (accessToken) req = req.clone({ setHeaders: { authorization: `Bearer ${accessToken}` } });

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status >= 500) console.error(error);

            if (
              error.status === 401 &&
              !NO_INTERCEPTOR_ROUTES.some(route => req.url.includes(route)) &&
              !NO_INTERCEPTOR_REQUESTS.some(request => req.url.includes(request))
            ) {
              if (!!refreshToken) {
                return this.authService.authenticationControllerRefresh({ refreshTokenDto: { refreshToken } }).pipe(
                  mergeMap(tokens => {
                    this.store.dispatch(authActions.loginSuccess({ ...tokens, shouldRedirect: false }));
                    this.store.dispatch(authActions.loadUser());
                    return next.handle(req.clone({ setHeaders: { authorization: `Bearer ${tokens.accessToken}` } }));
                  }),
                  catchError((e: any) => {
                    console.error(e);
                    if (e.status === 401) this.store.dispatch(authActions.logout());
                    return throwError(() => e);
                  }),
                );
              }
              return throwError(() => error);
            }
            return throwError(() => error);
          }),
        );
      }),
      catchError(error => throwError(() => error)),
    );
  }
}
