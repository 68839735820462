import { HttpClient } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { EstablishmentDto } from "@api";

type Locale =
  | "auto"
  | "bg"
  | "cs"
  | "da"
  | "de"
  | "el"
  | "en"
  | "en-GB"
  | "es"
  | "es-419"
  | "et"
  | "fi"
  | "fr"
  | "fr-CA"
  | "hu"
  | "id"
  | "it"
  | "ja"
  | "lt"
  | "lv"
  | "ms"
  | "mt"
  | "nb"
  | "nl"
  | "pl"
  | "pt"
  | "pt-BR"
  | "ro"
  | "ru"
  | "sk"
  | "sl"
  | "sv"
  | "th"
  | "tr"
  | "zh"
  | "zh-HK"
  | "zh-TW";

export const createTranslateLoader = (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, "./assets/i18n/", ".json");

export const parseLanguageToStripeLocale = (language: EstablishmentDto.EnabledLanguagesEnum): Locale => {
  switch (language.toLowerCase()) {
    case "catalan":
    case "spanish":
      return "es";
    case "english":
      return "en-GB";
    case "french":
      return "fr";
    case "german":
      return "de";
    case "italian":
      return "it";
    case "portuguese":
      return "pt";
  }

  return "es";
};
