import { TimetableDto } from "@api";

export const enumToWeekday = (weekday: TimetableDto.WeekdayEnum, startOnSunday: boolean = false): number =>
  startOnSunday ?({
    [TimetableDto.WeekdayEnum.Monday]: 1,
    [TimetableDto.WeekdayEnum.Tuesday]: 2,
    [TimetableDto.WeekdayEnum.Wednesday]: 3,
    [TimetableDto.WeekdayEnum.Thursday]: 4,
    [TimetableDto.WeekdayEnum.Friday]: 5,
    [TimetableDto.WeekdayEnum.Saturday]: 6,
    [TimetableDto.WeekdayEnum.Sunday]: 0,
  }[weekday]): ({
    [TimetableDto.WeekdayEnum.Monday]: 0,
    [TimetableDto.WeekdayEnum.Tuesday]: 1,
    [TimetableDto.WeekdayEnum.Wednesday]: 2,
    [TimetableDto.WeekdayEnum.Thursday]: 3,
    [TimetableDto.WeekdayEnum.Friday]: 4,
    [TimetableDto.WeekdayEnum.Saturday]: 5,
    [TimetableDto.WeekdayEnum.Sunday]: 6,
  }[weekday]);
