import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState, APP_FEATURE_KEY } from "./app.state";

const getState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const selectLanguage = createSelector(getState, state => state.language);

export const selectLoading = createSelector(getState, state => state.loading);

export const selectSidenavOpened = createSelector(getState, state => state.sidenavOpened);
export const selectSidenavMode = createSelector(getState, state => state.sidenavMode);
export const selectSidenavFolded = createSelector(getState, state => state.sidenavFolded);
