import { Directive, ElementRef, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, combineLatest, distinctUntilChanged, map, tap } from "rxjs";
import { fromApp, fromEstablishment, RootState } from "@store";

@Directive({
  selector: "[appShowWhenLoading]",
})
export class ShowWhenLoadingDirective implements OnInit {
  private readonly loading$: Observable<boolean> = this.store.select(fromApp.selectLoading);
  private readonly establishmentLoading$: Observable<boolean> = this.store.select(fromEstablishment.selectLoading);

  constructor(private readonly store: Store<RootState>, private elementRef: ElementRef) {}

  public ngOnInit(): void {
    combineLatest([this.loading$, this.establishmentLoading$]).pipe(
      map(([loading, establishmentLoading]) => loading || establishmentLoading),
      distinctUntilChanged(),
      tap(loading => {
        if (loading) this.elementRef.nativeElement.style.opacity = 1;
        else this.elementRef.nativeElement.style.opacity = 0;
      }),
    ).subscribe();
  }
}
