import { MemoizedSelector, createFeatureSelector, createSelector } from "@ngrx/store";
import { PRICE_FEATURE_KEY, PriceState } from "./price.state";
import { PriceDto } from "@api";

type MemoizedPrices = MemoizedSelector<object, PriceDto[], (s1: PriceState) => PriceDto[]>;

const getState = createFeatureSelector<PriceState>(PRICE_FEATURE_KEY);

export const selectLoading = createSelector(getState, state => state.loading);

export const selectProductPrices = (productId: number): MemoizedPrices => createSelector(getState, state =>
  Object.values(state.prices).filter((price: PriceDto) => price.productId === productId),
);
