import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ESTABLISHMENT_FEATURE_KEY, EstablishmentState } from "./establishment.state";
import { sortByWeekday } from "@utils";

const getState = createFeatureSelector<EstablishmentState>(ESTABLISHMENT_FEATURE_KEY);

export const selectLoading = createSelector(getState, state => state.loading);

export const selectEstablishments = createSelector(getState, state => Object.values(state.establishments));

export const selectSelectedEstablishmentId = createSelector(getState, state => state.selectedEstablishment);

export const selectSelectedEstablishment = createSelector(getState, selectSelectedEstablishmentId, (state, selectedEstablishmentId) =>
  state.establishments[selectedEstablishmentId],
);

export const selectSelectedEstablishmentTimetables = createSelector(
  getState,
  selectSelectedEstablishmentId,
  (state, selectedEstablishmentId) => !!state.timetables[selectedEstablishmentId]?.length
    ? [...state.timetables[selectedEstablishmentId]].sort(sortByWeekday)
    : [],
);

export const selectSelectedEstablishmentTranslations = createSelector(
  getState,
  selectSelectedEstablishmentId,
  (state, selectedEstablishmentId) => Object.values(state.translations[selectedEstablishmentId] || {}),
);

export const selectTemporaryLogo = createSelector(getState, state => state.temporaryLogo);

export const selectTemporaryImage = createSelector(getState, state => state.temporaryImage);

export const selectGooglePlace = createSelector(getState, state => state.googlePlace);
