import {
  EstablishmentDto,
  PriceDto,
  ProductDto,
  ProductV2ControllerCreateProductRequestParams,
  ProductV2ControllerCreateProductTranslationsRequestParams,
  ProductV2ControllerDeleteProductRequestParams,
  ProductV2ControllerDeleteProductTranslationsRequestParams,
  ProductV2ControllerGetProductTranslationsRequestParams,
  ProductV2ControllerGetProductsRequestParams,
  ProductV2ControllerUpdateProductRequestParams,
  ProductV2ControllerUpdateProductTranslationsRequestParams,
  TranslationDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getProducts = createAction("@product/get-products", props<ProductV2ControllerGetProductsRequestParams>());
export const getProductsSuccess = createAction("@product/get-products-success", props<{ products: ProductDto[] }>());
export const getProductsFailure = createAction("@product/get-products-failure", props<{ reason: string }>());

export const createProduct = createAction(
  "@product/create-product",
  props<ProductV2ControllerCreateProductRequestParams & {
    menuId?: number;
    translations: Record<EstablishmentDto.EnabledLanguagesEnum, Partial<TranslationDto>>;
    prices: Record<PriceDto.ServingEnum, Partial<PriceDto>>;
    callback?: () => void;
  }>());
export const createProductSuccess = createAction("@product/create-product-success", props<{ product: ProductDto; callback?: () => void; menuId?: number }>());
export const createProductFailure = createAction("@product/create-product-failure", props<{ reason: string }>());

export const updateProduct = createAction(
  "@product/update-product",
  props<ProductV2ControllerUpdateProductRequestParams & {
    translations: Record<EstablishmentDto.EnabledLanguagesEnum, Partial<TranslationDto>>;
    prices: Record<PriceDto.ServingEnum, Partial<PriceDto>>;
    callback?: () => void;
  }>());
export const updateProductSuccess = createAction("@product/update-product-success", props<{ product: ProductDto; callback?: () => void }>());
export const updateProductFailure = createAction("@product/update-product-failure", props<{ reason: string }>());

export const deleteProduct = createAction("@product/delete-product", props<ProductV2ControllerDeleteProductRequestParams>());
export const deleteProductSuccess = createAction("@product/delete-product-success", props<{ id: number }>());
export const deleteProductFailure = createAction("@product/delete-product-failure", props<{ reason: string }>());

export const getProductTranslations = createAction("@product/get-product-translations", props<ProductV2ControllerGetProductTranslationsRequestParams>());
export const getProductTranslationsSuccess = createAction("@product/get-product-translations-success", props<{ productId: number; translations: TranslationDto[] }>());
export const getProductTranslationsFailure = createAction("@product/get-product-translations-failure", props<{ reason: string }>());

export const createProductTranslation = createAction("@product/create-product-translation", props<ProductV2ControllerCreateProductTranslationsRequestParams>());
export const createProductTranslationSuccess = createAction("@product/create-product-translation-success", props<{ productId: number; translation: TranslationDto }>());
export const createProductTranslationFailure = createAction("@product/create-product-translation-failure", props<{ reason: string }>());

export const updateProductTranslation = createAction("@product/update-product-translation", props<ProductV2ControllerUpdateProductTranslationsRequestParams>());
export const updateProductTranslationSuccess = createAction("@product/update-product-translation-success", props<{ productId: number; translation: TranslationDto }>());
export const updateProductTranslationFailure = createAction("@product/update-product-translation-failure", props<{ reason: string }>());

export const deleteProductTranslation = createAction("@product/delete-product-translation", props<ProductV2ControllerDeleteProductTranslationsRequestParams>());
export const deleteProductTranslationSuccess = createAction("@product/delete-product-translation-success", props<{ productId: number; translation: TranslationDto }>());
export const deleteProductTranslationFailure = createAction("@product/delete-product-translation-failure", props<{ reason: string }>());

export const storeTemporaryImage = createAction("@product/store-temporary-image", props<{ dataURL: string }>());
export const removeTemporaryImage = createAction("@product/remove-temporary-image");
export const removeImage = createAction("@product/remove-image", props<{ url: string }>());
