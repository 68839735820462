import { registerLocaleData } from "@angular/common";
import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Observable } from "rxjs";
import { delay, filter, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { EstablishmentDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { appActions, fromApp, RootState } from "@store";
import { INIT, Store } from "@ngrx/store";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { MatSnackBar } from "@angular/material/snack-bar";

import catalan from "@angular/common/locales/ca";
import english from "@angular/common/locales/en";
import french from "@angular/common/locales/fr";
import german from "@angular/common/locales/de";
import italian from "@angular/common/locales/it";
import portuguese from "@angular/common/locales/pt";
import spanish from "@angular/common/locales/es";

@Injectable()
export class AppEffects {
  public storeInitialized$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(INIT),
      delay(5000),
      tap(() => {
        this.updates.versionUpdates.pipe(
          filter((event): event is VersionReadyEvent => event.type === "VERSION_READY"),
          switchMap(() => this.snackBar.open(this.translateService.instant("NEW_VERSION"), this.translateService.instant("UPDATE_NOW")).afterDismissed()),
          filter(result => result.dismissedByAction),
          map(() => this.updates.activateUpdate().then(() => location.reload())),
        );
      }),
    ), { dispatch:false },
  );

  public changeLanguage$: Observable<unknown> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.changeLanguage),
        tap(({ language }) => {
          this.translateService.use(language.toLowerCase());

          switch (language) {
            case EstablishmentDto.EnabledLanguagesEnum.Catalan:
              registerLocaleData(catalan);
              break;

            case EstablishmentDto.EnabledLanguagesEnum.English:
              registerLocaleData(english);
              break;

            case EstablishmentDto.EnabledLanguagesEnum.French:
              registerLocaleData(french);
              break;

            case EstablishmentDto.EnabledLanguagesEnum.German:
              registerLocaleData(german);
              break;

            case EstablishmentDto.EnabledLanguagesEnum.Italian:
              registerLocaleData(italian);
              break;

            case EstablishmentDto.EnabledLanguagesEnum.Portuguese:
              registerLocaleData(portuguese);
              break;

            case EstablishmentDto.EnabledLanguagesEnum.Spanish:
              registerLocaleData(spanish);
              break;
          }
        }),
      ),
    { dispatch: false },
  );

  public toggleSidenavFolded$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(appActions.toggleSidenavFolded),
      withLatestFrom(this.store.select(fromApp.selectSidenavFolded)),
      tap(([, folded]) => {
        document.documentElement.style.setProperty("--mat-sidenav-width", folded ? "80px" : "230px");
      }),
    ), { dispatch:false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly translateService: TranslateService,
    private readonly snackBar: MatSnackBar,
    private readonly updates: SwUpdate,
  ) {}
}
