import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateEstablishmentComponent } from "./create-establishment.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { LottieModule } from "ngx-lottie";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [
    CreateEstablishmentComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    LottieModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [
    CreateEstablishmentComponent,
  ],
})
export class CreateEstablishmentModule { }
