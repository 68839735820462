import { MemoizedSelector, createFeatureSelector, createSelector } from "@ngrx/store";
import { PRODUCT_FEATURE_KEY, ProductState } from "./product.state";
import { MenuCategoryDto, ProductDto, TranslationDto } from "@api";
import { fromMenu } from "@store";
import { sortByName } from "@utils";

type MemoizedProduct = MemoizedSelector<object, ProductDto, (s1: ProductState) => ProductDto>;
type MemoizedProducts = MemoizedSelector<object, ProductDto[], (s1: ProductState, s2: MenuCategoryDto[], s3: number) => ProductDto[]>;
type MemoizedProductTranslations = MemoizedSelector<object, TranslationDto[], (s1: ProductState) => Record<number, TranslationDto>>;

const getState = createFeatureSelector<ProductState>(PRODUCT_FEATURE_KEY);

export const selectLoading = createSelector(getState, state => state.loading);

export const selectProduct = (productId: number): MemoizedProduct => createSelector(getState, state => state.products[productId]);

export const selectProductTranslations = (productId: number): MemoizedProductTranslations =>
  createSelector(getState, state => Object.values(state.translations[productId] || {}));

export const selectCategoryProducts = (menuId: number): MemoizedProducts => createSelector(
  getState,
  fromMenu.selectMenuCategories(menuId),
  fromMenu.selectSelectedMenuCategoryId(menuId),
  (state, menuCategories, selectedMenuCategory) => {
    const categoryId = menuCategories.find(mc => mc.id === selectedMenuCategory)?.categoryId;

    return [...(Object.values(state.products).filter((product: ProductDto) => product.categoryId === categoryId) || [])].sort(sortByName);
  });

export const selectTemporaryImage = createSelector(getState, state => state.temporaryImage);
