import * as Sentry from "@sentry/angular-ivy";
import { Store } from "@ngrx/store";
import { appActions, authActions, fromApp, fromAuth, RootState } from "@store";
import { filter, first, Observable, tap } from "rxjs";
import { EstablishmentDto, User } from "@api";

export const appInititializerFactory = (store: Store<RootState>): () => Promise<void> =>
  async () => {
    try {
      const sidenavFolded$: Observable<boolean> = store.select(fromApp.selectSidenavFolded);
      const user$: Observable<User | undefined> = store.select(fromAuth.selectUser);
      const language$: Observable<EstablishmentDto.EnabledLanguagesEnum> = store.select(fromApp.selectLanguage);

      sidenavFolded$.pipe(
        tap(folded => {
          document.documentElement.style.setProperty("--mat-sidenav-width", folded ? "80px" : "230px");
        }),
        first(),
      ).subscribe();

      user$.pipe(
        filter(user => !!user),
        tap(() => store.dispatch(authActions.loadUser())),
        first(),
      ).subscribe();

      language$.pipe(
        tap(language => store.dispatch(appActions.changeLanguage({ language }))),
        first(),
      ).subscribe();
    } catch (error: unknown) {
      console.error(error);
      Sentry.captureException(error as Error);
    }
  };
