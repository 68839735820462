import { MenuCategoryDto, MenuCategoryProductDto, MenuDto, TimetableDto, TranslationDto } from "@api";

export const MENU_FEATURE_KEY = "menu";

export interface Menus {
  [menuId: number]: MenuDto;
}

export interface Timetables {
  [menuId: number]: TimetableDto[];
}

export interface Translations {
  [menuId: number]: Record<number, TranslationDto>;
}

export interface MenuCategories {
  [menuId: number]: MenuCategoryDto[];
}

export interface MenuCategoryProducts {
  [menuCategoryProductId: number]: MenuCategoryProductDto;
}

export interface MenuState {
  menus: Menus;
  timetables: Timetables;
  translations: Translations;
  categories: MenuCategories;
  products: MenuCategoryProducts;
  selectedMenuCategory: Record<number, number>;
  showInactiveCategories: boolean;
  loading: boolean;
}

export const initialState: MenuState = {
  menus: {},
  timetables: {},
  translations: {},
  categories: {},
  products: {},
  selectedMenuCategory: {},
  showInactiveCategories: true,
  loading: false,
};

export const persistedKeys: (keyof MenuState)[] = ["timetables", "translations", "selectedMenuCategory", "showInactiveCategories"];
