import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { FirebaseError } from "@angular/fire/app";
import { Storage, getDownloadURL, ref, uploadString } from "@angular/fire/storage";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { EstablishmentsService, User } from "@api";
import { ALERT_DIALOG_MAX_WIDTH, ALERT_DIALOG_WIDTH, AppRoutes, DEFAULT_ERROR_MESSAGE } from "@constants";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState, establishmentActions, fromAuth, fromEstablishment } from "@store";
import { isDefined } from "@utils";
import { CreateEstablishmentComponent } from "apps/admin/src/app/dialogs/create-establishment/create-establishment.component";
import { Observable, catchError, filter, map, mergeMap, of, tap, withLatestFrom } from "rxjs";

@Injectable()
export class EstablishmentEffects {
  public getEstablishments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.getEstablishments),
      mergeMap(() =>
        this.establishmentService.establishmentV2ControllerGetSelfEstablishments().pipe(
          map(establishments => establishmentActions.getEstablishmentsSuccess({ establishments })),
          catchError((error: HttpErrorResponse) => of(establishmentActions.getEstablishmentsFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public getEstablishmentsSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.getEstablishmentsSuccess),
      withLatestFrom(this.store.select(fromEstablishment.selectSelectedEstablishmentId)),
      filter(([{ establishments }, selectedEstablishmentId]) => selectedEstablishmentId === 0 && !!establishments.length),
      tap(([{ establishments }]) => {
        this.store.dispatch(establishmentActions.selectEstablishment({ id: establishments[0].id! }));
      }),
    ), { dispatch: false },
  );

  public openCreateEstablishmentModal$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.getEstablishmentsSuccess),
      filter(({ establishments }) => !establishments.length),
      tap(() => {
        this.dialog.open<CreateEstablishmentComponent, void, void>(CreateEstablishmentComponent, {
          width: ALERT_DIALOG_WIDTH,
          maxWidth: ALERT_DIALOG_MAX_WIDTH,
          disableClose: true,
        });
      }),
    ), { dispatch:false },
  );

  public createEstablishment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.createEstablishment),
      mergeMap(({ createEstablishmentDto, callback }) =>
        this.establishmentService.establishmentV2ControllerCreateEstablishment({ createEstablishmentDto }).pipe(
          mergeMap(establishment => [
            establishmentActions.createEstablishmentSuccess({ establishment, callback }),
            establishmentActions.removeGooglePlace(),
            establishmentActions.removeTemporaryLogo(),
            establishmentActions.removeTemporaryImage(),
          ]),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.createEstablishmentFailure({ reason: error.error?.error ?? error.statusText })),
          ),
        ),
      ),
    ),
  );

  public createEstablishmentSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.createEstablishmentSuccess),
      tap(({ callback }) => {
        callback();
        this.router.navigate([AppRoutes.Main, AppRoutes.Information, AppRoutes.General]);
      }),
    ), { dispatch: false },
  );

  public updateEstablishment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.updateEstablishment),
      withLatestFrom(
        this.store.select(fromEstablishment.selectTemporaryLogo),
        this.store.select(fromEstablishment.selectTemporaryImage),
      ),
      mergeMap(([{ establishmentId, updateEstablishmentDto }, logo, image]) =>
        this.uploadEstablishmentImage("logo", logo).pipe(
          mergeMap(logoURL =>
            this.uploadEstablishmentImage("image", image).pipe(
              mergeMap(imageURL =>
                this.establishmentService.establishmentV2ControllerUpdateEstablishment({ establishmentId, updateEstablishmentDto: {
                  ...updateEstablishmentDto,
                  logo: logoURL || updateEstablishmentDto.logo,
                  image: imageURL || updateEstablishmentDto.image,
                } }).pipe(
                  map(establishment => establishmentActions.updateEstablishmentSuccess({ establishment })),
                  catchError((error: HttpErrorResponse) =>
                    of(establishmentActions.updateEstablishmentFailure({ reason: error.error?.error })),
                  ),
                ),
              ),
              catchError((error: FirebaseError) => of(establishmentActions.updateEstablishmentFailure({ reason: error.message }))),
            ),
          ),
          catchError((error: FirebaseError) => of(establishmentActions.updateEstablishmentFailure({ reason: error.message }))),
        ),
      ),
    ),
  );

  public useGoogleImage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.useGoogleImage),
      mergeMap(({ establishmentId, useGoogleImageDto }) =>
        this.establishmentService.establishmentV2ControllerUseGoogleImage({ establishmentId, useGoogleImageDto }).pipe(
          map(establishment => establishmentActions.useGoogleImageSuccess({ establishment })),
          catchError((error: HttpErrorResponse) => of(establishmentActions.useGoogleImageFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public getEstablishmentTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.getEstablishmentTimetables),
      mergeMap(({ establishmentId }) =>
        this.establishmentService.establishmentV2ControllerGetEstablishmentTimetables({ establishmentId }).pipe(
          map(timetables => establishmentActions.getEstablishmentTimetablesSuccess({ establishmentId, timetables })),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.getEstablishmentTimetablesFailure({ reason: error.error?.error })),
          ),
        ),
      ),
    ),
  );

  public upsertEstablishmentTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.upsertEstablishmentTimetables),
      mergeMap(({ establishmentId, createEstablishmentTimetables }) =>
        this.establishmentService.establishmentV2ControllerCreateOrUpdateEstablishmentTimetables({
          establishmentId,
          createEstablishmentTimetables,
        }).pipe(
          map(timetables => establishmentActions.upsertEstablishmentTimetablesSuccess({ establishmentId, timetables })),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.upsertEstablishmentTimetablesFailure({ reason: error.error?.error })),
          ),
        ),
      ),
    ),
  );

  public getEstablishmentTranslations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.getEstablishmentTranslations),
      mergeMap(({ establishmentId }) =>
        this.establishmentService.establishmentV2ControllerGetEstablishmentTranslations({ establishmentId }).pipe(
          map(translations => establishmentActions.getEstablishmentTranslationsSuccess({ establishmentId, translations })),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.getEstablishmentTranslationsFailure({ reason: error.error?.error })),
          ),
        ),
      ),
    ),
  );

  public createEstablishmentTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.createEstablishmentTranslation),
      mergeMap(({ establishmentId, createTranslationDto }) =>
        this.establishmentService.establishmentV2ControllerCreateEstablishmentTranslations({
          establishmentId,
          createTranslationDto,
        }).pipe(
          map(translation => establishmentActions.createEstablishmentTranslationSuccess({ establishmentId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.createEstablishmentTranslationFailure({ reason: error.error?.error })),
          ),
        ),
      ),
    ),
  );

  public updateEstablishmentTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.updateEstablishmentTranslation),
      mergeMap(({ establishmentId, translationId, createTranslationDto }) =>
        this.establishmentService.establishmentV2ControllerUpdateEstablishmentTranslations({
          establishmentId,
          translationId,
          createTranslationDto,
        }).pipe(
          map(translation => establishmentActions.updateEstablishmentTranslationSuccess({ establishmentId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.updateEstablishmentTranslationFailure({ reason: error.error?.error })),
          ),
        ),
      ),
    ),
  );

  public deleteEstablishmentTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.deleteEstablishmentTranslation),
      mergeMap(({ establishmentId, translationId }) =>
        this.establishmentService.establishmentV2ControllerDeleteEstablishmentTranslations({
          establishmentId,
          translationId,
        }).pipe(
          map(translation => establishmentActions.deleteEstablishmentTranslationSuccess({ establishmentId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(establishmentActions.deleteEstablishmentTranslationFailure({ reason: error.error?.error })),
          ),
        ),
      ),
    ),
  );

  public deleteEstablishment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.deleteEstablishment),
      mergeMap(({ id }) =>
        this.establishmentService.establishmentControllerRemove({ id }).pipe(
          map(() => establishmentActions.deleteEstablishmentSuccess({ id })),
          catchError((error: HttpErrorResponse) => of(establishmentActions.deleteEstablishmentFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public deleteEstablishmentSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(establishmentActions.deleteEstablishmentSuccess),
      tap(() => this.router.navigate([AppRoutes.Main, AppRoutes.Dashboard])),
    ), { dispatch: false },
  );

  public showError$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        establishmentActions.createEstablishmentFailure,
        establishmentActions.getEstablishmentsFailure,
        establishmentActions.deleteEstablishmentFailure,
        establishmentActions.updateEstablishmentFailure,
        establishmentActions.getEstablishmentTimetablesFailure,
        establishmentActions.getEstablishmentTranslationsFailure,
        establishmentActions.upsertEstablishmentTimetablesFailure,
        establishmentActions.createEstablishmentTranslationFailure,
        establishmentActions.deleteEstablishmentTranslationFailure,
        establishmentActions.updateEstablishmentTranslationFailure,
      ),
      tap(({ reason }) => this.snackBar.open(this.translateService.instant(reason || DEFAULT_ERROR_MESSAGE))),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly establishmentService: EstablishmentsService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly translateService: TranslateService,
    private readonly storage: Storage = inject(Storage),
  ) {}

  private uploadEstablishmentImage(type: "logo" | "image", value: string | null): Observable<string> {
    if (!value) return of("");

    return this.store.select(fromAuth.selectUser).pipe(
      filter<User | undefined>(isDefined),
      mergeMap(user => {
        const path = `${user!.id}/${Date.now()}_${type}`;
        const storageRef = ref(this.storage, path);

        return new Observable<string>(observer => {
          uploadString(storageRef, value, "data_url")
            .then(() => getDownloadURL(storageRef).then(url => observer.next(url)))
            .catch(error => observer.error(error));
        });
      }),
    );
  }
}
