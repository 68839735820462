import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CreateEstablishmentDto } from "@api";
import { Store } from "@ngrx/store";
import { RootState, establishmentActions, fromEstablishment } from "@store";
import { notEmpty } from "@validators";
import { Observable } from "rxjs";

@Component({
  selector: "admin-create-establishment",
  templateUrl: "./create-establishment.component.html",
  styleUrls: ["./create-establishment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEstablishmentComponent implements OnInit {
  public readonly loading$: Observable<boolean> = this.store.select(fromEstablishment.selectLoading);
  public readonly formControl: FormControl = new FormControl("", [Validators.required, Validators.maxLength(50), notEmpty()]);

  constructor(
    private readonly store: Store<RootState>,
    private readonly dialogRef: MatDialogRef<CreateEstablishmentComponent>,
  ) {}

  public ngOnInit(): void {
    this.formControl.reset("");
  }

  public save(): void {
    if (this.formControl.invalid) {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
      return;
    }

    this.store.dispatch(establishmentActions.createEstablishment({
      createEstablishmentDto: {
        name: this.formControl.value,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone as CreateEstablishmentDto.TimezoneEnum,
      },
      callback: () => this.dialogRef.close(),
    }));
  }
}
