/* eslint-disable @typescript-eslint/no-explicit-any */
import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { ActionReducer, ActionReducerMap, MetaReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";

import { APP_FEATURE_KEY, AppState, initialState as app, persistedKeys as appPersistedKeys } from "./store/app/app.state";
import { AUTH_FEATURE_KEY, AuthState, initialState as auth, persistedKeys as authPersistedKeys } from "./store/auth/auth.state";
import { CATEGORY_FEATURE_KEY, CategoryState, initialState as category, persistedKeys as categoryPersistedKeys } from "./store/category/category.state";
import { ESTABLISHMENT_FEATURE_KEY, EstablishmentState, initialState as establishment, persistedKeys as establishmentPersistedKeys } from "./store/establishment/establishment.state";
import { MENU_FEATURE_KEY, MenuState, initialState as menu, persistedKeys as menuPersistedKeys } from "./store/menu/menu.state";
import { PRICE_FEATURE_KEY, PriceState, initialState as price, persistedKeys as pricePersistedKeys } from "./store/price/price.state";
import { PRODUCT_FEATURE_KEY, ProductState, initialState as product, persistedKeys as productPersistedKeys } from "./store/product/product.state";

import { reducer as appReducer } from "./store/app/app.reducer";
import { reducer as authReducer } from "./store/auth/auth.reducer";
import { reducer as categoryReducer } from "./store/category/category.reducer";
import { reducer as establishmentReducer } from "./store/establishment/establishment.reducer";
import { reducer as menuReducer } from "./store/menu/menu.reducer";
import { reducer as priceReducer } from "./store/price/price.reducer";
import { reducer as productReducer } from "./store/product/product.reducer";

import { AppEffects } from "./store/app/app.effects";
import { AuthEffects } from "./store/auth/auth.effects";
import { CategoryEffects } from "./store/category/category.effects";
import { EstablishmentEffects } from "./store/establishment/establishment.effects";
import { MenuEffects } from "./store/menu/menu.effects";
import { PriceEffects } from "./store/price/price.effects";
import { ProductEffects } from "./store/product/product.effects";
import { RouterEffects } from "./store/router/router.effects";

import { logoutMetaReducer } from "./store/logout-meta-reducer";

export interface RootState {
  [APP_FEATURE_KEY]: AppState;
  [AUTH_FEATURE_KEY]: AuthState;
  [CATEGORY_FEATURE_KEY]: CategoryState;
  [ESTABLISHMENT_FEATURE_KEY]: EstablishmentState;
  [MENU_FEATURE_KEY]: MenuState;
  [PRICE_FEATURE_KEY]: PriceState;
  [PRODUCT_FEATURE_KEY]: ProductState;
  router: RouterReducerState<any>;
}

export const initialRootState: RootState = {
  app,
  auth,
  category,
  establishment,
  menu,
  price,
  product,
  router: { navigationId: 0, state: {} },
};

export const reducers: ActionReducerMap<RootState> = {
  [APP_FEATURE_KEY]: appReducer,
  [AUTH_FEATURE_KEY]: authReducer,
  [CATEGORY_FEATURE_KEY]: categoryReducer,
  [ESTABLISHMENT_FEATURE_KEY]: establishmentReducer,
  [MENU_FEATURE_KEY]: menuReducer,
  [PRICE_FEATURE_KEY]: priceReducer,
  [PRODUCT_FEATURE_KEY]: productReducer,
  router: routerReducer,
};

export const effects = [
  AppEffects,
  AuthEffects,
  CategoryEffects,
  EstablishmentEffects,
  MenuEffects,
  PriceEffects,
  ProductEffects,
  RouterEffects,
];

export const localStorageReduxSync = (reducer: ActionReducer<RootState>): ActionReducer<any> => localStorageSync(
  {
    keys: [
      { [APP_FEATURE_KEY]: appPersistedKeys },
      { [AUTH_FEATURE_KEY]: authPersistedKeys },
      { [CATEGORY_FEATURE_KEY]: categoryPersistedKeys },
      { [ESTABLISHMENT_FEATURE_KEY]: establishmentPersistedKeys },
      { [MENU_FEATURE_KEY]: menuPersistedKeys },
      { [PRICE_FEATURE_KEY]: pricePersistedKeys },
      { [PRODUCT_FEATURE_KEY]: productPersistedKeys },
    ],
    rehydrate: true,
  })(reducer);

export const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync, logoutMetaReducer];

export * from "./store/store.module";

export * as appActions from "./store/app/app.actions";
export * as authActions from "./store/auth/auth.actions";
export * as categoryActions from "./store/category/category.actions";
export * as establishmentActions from "./store/establishment/establishment.actions";
export * as menuActions from "./store/menu/menu.actions";
export * as priceActions from "./store/price/price.actions";
export * as productActions from "./store/product/product.actions";

export * as fromApp from "./store/app/app.selectors";
export * as fromAuth from "./store/auth/auth.selectors";
export * as fromCategory from "./store/category/category.selectors";
export * as fromEstablishment from "./store/establishment/establishment.selectors";
export * as fromMenu from "./store/menu/menu.selectors";
export * as fromPrice from "./store/price/price.selectors";
export * as fromProduct from "./store/product/product.selectors";
export * as fromRouter from "./store/router/router.selectors";
