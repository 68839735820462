import {
  EstablishmentV2ControllerGetEstablishmentMenusRequestParams,
  EstablishmentV2ControllerReorderEstablishmentMenusRequestParams,
  MenuCategoryDto,
  MenuCategoryProductDto,
  MenuDto,
  MenuV2ControllerCreateMenuCategoryProductRequestParams,
  MenuV2ControllerCreateMenuCategoryRequestParams,
  MenuV2ControllerCreateMenuRequestParams,
  MenuV2ControllerCreateMenuTranslationsRequestParams,
  MenuV2ControllerCreateOrUpdateMenuTimetablesRequestParams,
  MenuV2ControllerDeleteMenuCategoryProductRequestParams,
  MenuV2ControllerDeleteMenuCategoryRequestParams,
  MenuV2ControllerDeleteMenuRequestParams,
  MenuV2ControllerDeleteMenuTimetablesRequestParams,
  MenuV2ControllerDeleteMenuTranslationsRequestParams,
  MenuV2ControllerGetMenuCategoriesRequestParams,
  MenuV2ControllerGetMenuProductsRequestParams,
  MenuV2ControllerGetMenuRequestParams,
  MenuV2ControllerGetMenuTimetablesRequestParams,
  MenuV2ControllerGetMenuTranslationsRequestParams,
  MenuV2ControllerUpdateMenuCategoriesRequestParams,
  MenuV2ControllerUpdateMenuCategoryProductRequestParams,
  MenuV2ControllerUpdateMenuCategoryProductsRequestParams,
  MenuV2ControllerUpdateMenuCategoryRequestParams,
  MenuV2ControllerUpdateMenuRequestParams,
  MenuV2ControllerUpdateMenuTranslationsRequestParams,
  TimetableDto,
  TranslationDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getEstablishmentMenus = createAction("@menu/get-establishment-menus", props<EstablishmentV2ControllerGetEstablishmentMenusRequestParams>());
export const getEstablishmentMenusSuccess = createAction("@menu/get-establishment-menus-success", props<{ menus: MenuDto[] }>());
export const getEstablishmentMenusFailure = createAction("@menu/get-establishment-menus-failure", props<{ reason: string }>());

export const reorderEstablishmentMenus = createAction("@menu/reorder-establishment-menus", props<EstablishmentV2ControllerReorderEstablishmentMenusRequestParams>());
export const reorderEstablishmentMenusSuccess = createAction("@menu/reorder-establishment-menus-success", props<{ menus: MenuDto[] }>());
export const reorderEstablishmentMenusFailure = createAction("@menu/reorder-establishment-menus-failure", props<{ reason: string }>());

export const getMenu = createAction("@menu/get-menu", props<MenuV2ControllerGetMenuRequestParams>());
export const getMenuSuccess = createAction("@menu/get-menu-success", props<{ menu: MenuDto }>());
export const getMenuFailure = createAction("@menu/get-menu-failure", props<{ reason: string }>());

export const createMenu = createAction("@menu/create-menu", props<MenuV2ControllerCreateMenuRequestParams>());
export const createMenuSuccess = createAction("@menu/create-menu-success", props<{ menu: MenuDto }>());
export const createMenuFailure = createAction("@menu/create-menu-failure", props<{ reason: string }>());

export const editMenu = createAction("@menu/edit-menu", props<MenuV2ControllerUpdateMenuRequestParams>());
export const editMenuSuccess = createAction("@menu/edit-menu-success", props<{ menu: MenuDto }>());
export const editMenuFailure = createAction("@menu/edit-menu-failure", props<{ reason: string }>());

export const deleteMenu = createAction("@menu/delete-menu", props<MenuV2ControllerDeleteMenuRequestParams>());
export const deleteMenuSuccess = createAction("@menu/delete-menu-success", props<{ id: number }>());
export const deleteMenuFailure = createAction("@menu/delete-menu-failure", props<{ reason: string }>());

export const getMenuTranslations = createAction("@menu/get-menu-translations", props<MenuV2ControllerGetMenuTranslationsRequestParams>());
export const getMenuTranslationsSuccess = createAction("@menu/get-menu-translations-success", props<{ menuId: number; translations: TranslationDto[] }>());
export const getMenuTranslationsFailure = createAction("@menu/get-menu-translations-failure", props<{ reason: string }>());

export const createMenuTranslation = createAction("@menu/create-menu-translation", props<MenuV2ControllerCreateMenuTranslationsRequestParams>());
export const createMenuTranslationSuccess = createAction("@menu/create-menu-translation-success", props<{ menuId: number; translation: TranslationDto }>());
export const createMenuTranslationFailure = createAction("@menu/create-menu-translation-failure", props<{ reason: string }>());

export const updateMenuTranslation = createAction("@menu/update-menu-translation", props<MenuV2ControllerUpdateMenuTranslationsRequestParams>());
export const updateMenuTranslationSuccess = createAction("@menu/update-menu-translation-success", props<{ menuId: number; translation: TranslationDto }>());
export const updateMenuTranslationFailure = createAction("@menu/update-menu-translation-failure", props<{ reason: string }>());

export const deleteMenuTranslation = createAction("@menu/delete-menu-translation", props<MenuV2ControllerDeleteMenuTranslationsRequestParams>());
export const deleteMenuTranslationSuccess = createAction("@menu/delete-menu-translation-success", props<{ menuId: number; translation: TranslationDto }>());
export const deleteMenuTranslationFailure = createAction("@menu/delete-menu-translation-failure", props<{ reason: string }>());

export const getMenuCategories = createAction("@menu/get-menu-categories", props<MenuV2ControllerGetMenuCategoriesRequestParams>());
export const getMenuCategoriesSuccess = createAction("@menu/get-menu-categories-success", props<{ menuId: number; categories: MenuCategoryDto[] }>());
export const getMenuCategoriesFailure = createAction("@menu/get-menu-categories-failure", props<{ reason: string }>());

export const createMenuCategory = createAction("@menu/create-menu-category", props<MenuV2ControllerCreateMenuCategoryRequestParams>());
export const createMenuCategorySuccess = createAction("@menu/create-menu-category-success", props<{ menuId: number; menuCategory: MenuCategoryDto }>());
export const createMenuCategoryFailure = createAction("@menu/create-menu-category-failure", props<{ reason: string }>());

export const updateMenuCategory = createAction("@menu/update-menu-category", props<MenuV2ControllerUpdateMenuCategoryRequestParams & { callback: () => void }>());
export const updateMenuCategorySuccess = createAction("@menu/update-menu-category-success", props<{ menuId: number; menuCategory: MenuCategoryDto; callback: () => void }>());
export const updateMenuCategoryFailure = createAction("@menu/update-menu-category-failure", props<{ reason: string }>());

export const deleteMenuCategory = createAction("@menu/delete-menu-category", props<MenuV2ControllerDeleteMenuCategoryRequestParams & { callback: () => void }>());
export const deleteMenuCategorySuccess = createAction("@menu/delete-menu-category-success", props<MenuV2ControllerDeleteMenuCategoryRequestParams & { callback: () => void }>());
export const deleteMenuCategoryFailure = createAction("@menu/delete-menu-category-failure", props<{ reason: string }>());

export const updateMenuCategories = createAction("@menu/update-menu-categories", props<MenuV2ControllerUpdateMenuCategoriesRequestParams>());
export const updateMenuCategoriesSuccess = createAction("@menu/update-menu-categories-success", props<{ menuId: number; categories: MenuCategoryDto[] }>());
export const updateMenuCategoriesFailure = createAction("@menu/update-menu-categories-failure", props<{ reason: string }>());

export const selectMenuCategory = createAction("@menu/select-menu-category", props<{ menuId: number; menuCategoryId: number }>());

export const toggleShowInactiveCategories = createAction("@menu/toggle-show-inactive-categories", props<{ menuId: number }>());

export const getMenuProducts = createAction("@menu/get-menu-products", props<MenuV2ControllerGetMenuProductsRequestParams>());
export const getMenuProductsSuccess = createAction("@menu/get-menu-products-success", props<{ products: MenuCategoryProductDto[] }>());
export const getMenuProductsFailure = createAction("@menu/get-menu-products-failure", props<{ reason: string }>());

export const createMenuProduct = createAction("@menu/create-menu-product", props<MenuV2ControllerCreateMenuCategoryProductRequestParams & { callback?: () => void }>());
export const createMenuProductSuccess = createAction("@menu/create-menu-product-success", props<{ product: MenuCategoryProductDto; callback?: () => void }>());
export const createMenuProductFailure = createAction("@menu/create-menu-product-failure", props<{ reason: string }>());

export const updateMenuProduct = createAction("@menu/update-menu-product", props<MenuV2ControllerUpdateMenuCategoryProductRequestParams>());
export const updateMenuProductSuccess = createAction("@menu/update-menu-product-success", props<{ product: MenuCategoryProductDto }>());
export const updateMenuProductFailure = createAction("@menu/update-menu-product-failure", props<{ reason: string }>());

export const updateMenuProducts = createAction("@menu/update-menu-products", props<MenuV2ControllerUpdateMenuCategoryProductsRequestParams>());
export const updateMenuProductsSuccess = createAction("@menu/update-menu-products-success", props<{ products: MenuCategoryProductDto[] }>());
export const updateMenuProductsFailure = createAction("@menu/update-menu-products-failure", props<{ reason: string }>());

export const deleteMenuProduct = createAction("@menu/delete-menu-product", props<MenuV2ControllerDeleteMenuCategoryProductRequestParams & { callback?: () => void }>());
export const deleteMenuProductSuccess = createAction("@menu/delete-menu-product-success", props<MenuV2ControllerDeleteMenuCategoryProductRequestParams & { callback?: () => void }>());
export const deleteMenuProductFailure = createAction("@menu/delete-menu-product-failure", props<{ reason: string }>());

export const getMenuTimetables = createAction("@menu/get-menu-timetables", props<MenuV2ControllerGetMenuTimetablesRequestParams>());
export const getMenuTimetablesSuccess = createAction("@menu/get-menu-timetables-success", props<{ menuId: number; timetables: TimetableDto[] }>());
export const getMenuTimetablesFailure = createAction("@menu/get-menu-timetables-failure", props<{ reason: string }>());

export const upsertMenuTimetables = createAction("@menu/upsert-menu-timatebles", props<MenuV2ControllerCreateOrUpdateMenuTimetablesRequestParams>());
export const upsertMenuTimetablesSuccess = createAction("@menu/upsert-menu-timatebles-success", props<{ menuId: number; timetables: TimetableDto[] }>());
export const upsertMenuTimetablesFailure = createAction("@menu/upsert-menu-timatebles-failure", props<{ reason: string }>());

export const deleteMenuTimetables = createAction("@menu/delete-menu-timatebles", props<MenuV2ControllerDeleteMenuTimetablesRequestParams>());
export const deleteMenuTimetablesSuccess = createAction("@menu/delete-menu-timatebles-success", props<{ menuId: number }>());
export const deleteMenuTimetablesFailure = createAction("@menu/delete-menu-timatebles-failure", props<{ reason: string }>());
