import { Action, createReducer, on } from "@ngrx/store";
import { ProductState, Products, initialState } from "./product.state";
import { productActions } from "@store";
import { TranslationDto } from "@api";

const productReducer = createReducer(
  initialState,

  on(
    productActions.getProducts,
    productActions.createProduct,
    productActions.updateProduct,
    productActions.deleteProduct,
    productActions.getProductTranslations,
    productActions.createProductTranslation,
    productActions.updateProductTranslation,
    productActions.deleteProductTranslation,
    state => ({ ...state, loading: true }),
  ),

  on(productActions.getProductsSuccess, (state, { products }) => ({
    ...state,
    products: {
      ...state.products,
      ...products.reduce((result, product) => {
        result[product.id] = product;
        return result;
      }, {} as Products),
    },
    loading: false,
  })),

  on(productActions.createProductSuccess, productActions.updateProductSuccess, (state, { product }) => ({
    ...state,
    products: {
      ...state.products,
      [product.id!]: product,
    },
    temporaryImage: null,
    loading: false,
  })),

  on(productActions.deleteProductSuccess, (state, { id }) => ({
    ...state,
    products: Object.values(state.products).reduce((result, product) => {
      if (product.id !== id) result[product.id!] = product;
      return result;
    }, {} as Products),
    loading: false,
  })),

  on(productActions.getProductTranslationsSuccess, (state, { productId, translations }) => ({
    ...state,
    translations: {
      ...state.translations,
      [productId]: translations.reduce((result, translation) => {
        result[translation.id] = translation;
        return result;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(
    productActions.createProductTranslationSuccess,
    productActions.updateProductTranslationSuccess,
    (state, { productId, translation }) => ({
      ...state,
      translations: {
        ...state.translations,
        [productId]: {
          ...(state.translations[productId] || {}),
          [translation.id!]: translation,
        },
      },
      loading: false,
    })),

  on(productActions.deleteProductTranslationSuccess, (state, { productId, translation: deleted }) => ({
    ...state,
    translations: {
      ...state.translations,
      [productId]: Object.values(state.translations[productId]).reduce((translations, translation) => {
        if (translation.id !== deleted.id) translations[translation.id!] = translation;
        return translations;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(productActions.storeTemporaryImage, (state, { dataURL }) => ({ ...state, temporaryImage: dataURL })),

  on(productActions.removeTemporaryImage, state => ({ ...state, temporaryImage: null })),

  on(
    productActions.getProductsFailure,
    productActions.createProductFailure,
    productActions.updateProductFailure,
    productActions.deleteProductFailure,
    productActions.getProductTranslationsFailure,
    productActions.createProductTranslationFailure,
    productActions.updateProductTranslationFailure,
    productActions.deleteProductTranslationFailure,
    state => ({ ...state, loading: false }),
  ),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: ProductState | undefined, action: Action) => productReducer(state, action);
