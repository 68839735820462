import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RegisterSuccessComponent } from "./register-success.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    RegisterSuccessComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
  ],
  exports: [
    RegisterSuccessComponent,
  ],
})
export class RegisterSuccessModule { }
