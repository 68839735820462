/**
 * Oh My Menu
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.3.07
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TimetableDto { 
    id: number;
    establishmentId: number;
    menuId: number;
    weekday: TimetableDto.WeekdayEnum;
    opens: boolean;
    hours: Array<Array<string>>;
    createdAt: string;
    updatedAt: string;
}
export namespace TimetableDto {
    export type WeekdayEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const WeekdayEnum = {
        Monday: 'MONDAY' as WeekdayEnum,
        Tuesday: 'TUESDAY' as WeekdayEnum,
        Wednesday: 'WEDNESDAY' as WeekdayEnum,
        Thursday: 'THURSDAY' as WeekdayEnum,
        Friday: 'FRIDAY' as WeekdayEnum,
        Saturday: 'SATURDAY' as WeekdayEnum,
        Sunday: 'SUNDAY' as WeekdayEnum
    };
}


