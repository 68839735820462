import { createAction, props } from "@ngrx/store";
import { EstablishmentDto } from "@api";
import { MatDrawerMode } from "@angular/material/sidenav";

export const changeLanguage = createAction("@app/change-language", props<{ language: EstablishmentDto.EnabledLanguagesEnum }>());

export const showLoader = createAction("@app/show-loader");
export const hideLoader = createAction("@app/hide-loader");

export const toggleSidenav = createAction("@app/toggle-sidenav", (payload: { opened?: boolean } = {}) => payload);
export const toggleSidenavMode = createAction("@app/toggle-sidenav-mode", props<{ mode: MatDrawerMode }>());
export const toggleSidenavFolded = createAction("@app/toggle-sidenav-folded");
