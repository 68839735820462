import { MemoizedSelector, createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthState, AUTH_FEATURE_KEY, AuthLoading } from "./auth.state";

type MemoizedAuthLoading = MemoizedSelector<object, boolean, (s1: AuthState) => boolean>;

const getState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);

export const selectLoading = (key: keyof AuthLoading): MemoizedAuthLoading => createSelector(getState, state => state.loading[key]);

export const selectAccessToken = createSelector(getState, state => state.accessToken);
export const selectRefreshToken = createSelector(getState, state => state.refreshToken);

export const selectUser = createSelector(getState, state => state.user);

export const selectDashboard = createSelector(getState, state => state.dashboard);

export const selectSimpleStatistics = createSelector(getState, state => state.statisticsSimple);

export const selectSuccessMessage = createSelector(getState, state => state.successMessage);
