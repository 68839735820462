import {
  PriceDto,
  PriceV2ControllerGetPricesRequestParams,
  ProductV2ControllerCreateProductPricesRequestParams,
  ProductV2ControllerDeleteProductPriceRequestParams,
  ProductV2ControllerUpdateProductPriceRequestParams,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getPrices = createAction("@price/get-prices", props<PriceV2ControllerGetPricesRequestParams>());
export const getPricesSuccess = createAction("@price/get-prices-success", props<{ prices: PriceDto[] }>());
export const getPricesFailure = createAction("@price/get-prices-failure", props<{ reason: string }>());

export const createProductPrice = createAction("@product/create-product-price", props<ProductV2ControllerCreateProductPricesRequestParams>());
export const createProductPriceSuccess = createAction("@product/create-product-price-success", props<{ price: PriceDto }>());
export const createProductPriceFailure = createAction("@product/create-product-price-failure", props<{ reason: string }>());

export const updateProductPrice = createAction("@product/update-product-price", props<ProductV2ControllerUpdateProductPriceRequestParams>());
export const updateProductPriceSuccess = createAction("@product/update-product-price-success", props<{ price: PriceDto }>());
export const updateProductPriceFailure = createAction("@product/update-product-price-failure", props<{ reason: string }>());

export const deleteProductPrice = createAction("@product/delete-product-price", props<ProductV2ControllerDeleteProductPriceRequestParams>());
export const deleteProductPriceSuccess = createAction("@product/delete-product-price-success", props<{ price: PriceDto }>());
export const deleteProductPriceFailure = createAction("@product/delete-product-price-failure", props<{ reason: string }>());
