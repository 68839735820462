import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Storage, getDownloadURL, ref, uploadString } from "@angular/fire/storage";
import { EstablishmentDto, PriceDto, ProductsService, TranslationDto, User } from "@api";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState, fromAuth, fromEstablishment, fromPrice, fromProduct, priceActions, productActions } from "@store";
import { getHttpErrorMessage, isDefined } from "@utils";
import { Observable, catchError, filter, map, mergeMap, of, tap, withLatestFrom } from "rxjs";

@Injectable()
export class ProductEffects {
  public getProducts$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.getProducts),
      mergeMap(({ categoryId }) =>
        this.productService.productV2ControllerGetProducts({ categoryId }).pipe(
          map(products => productActions.getProductsSuccess({ products })),
          catchError((error: HttpErrorResponse) => of(productActions.getProductsFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public createProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.createProduct),
      withLatestFrom(
        this.store.select(fromEstablishment.selectSelectedEstablishment),
        this.store.select(fromProduct.selectTemporaryImage),
      ),
      mergeMap(([{ createProductDto, menuId, translations, prices, callback }, establishment, image]) =>
        this.uploadProductImage(image).pipe(
          mergeMap(imageURL =>
            this.productService.productV2ControllerCreateProduct({
              createProductDto: { ...createProductDto, image: imageURL || undefined },
            }).pipe(
              mergeMap(product => this.saveProductTranslations$(establishment.enabledLanguages || [], [], translations, product.id).pipe(
                mergeMap(translationEffects => this.saveProductPrices$([], prices, product.id).pipe(
                  mergeMap(priceEffects => [
                    ...translationEffects,
                    ...priceEffects,
                    productActions.createProductSuccess({ product, callback, menuId }),
                  ]),
                )))),
              catchError((error: HttpErrorResponse) => of(productActions.createProductFailure({ reason: error.error?.error }))),
            ),
          ),
        ),
      ),
    ),
  );

  public updateProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.updateProduct),
      concatLatestFrom(({ productId }) => [
        this.store.select(fromEstablishment.selectSelectedEstablishment),
        this.store.select(fromProduct.selectTemporaryImage),
        this.store.select(fromProduct.selectProductTranslations(productId)),
        this.store.select(fromPrice.selectProductPrices(productId)),
      ]),
      mergeMap(([{ productId, updateProductDto, translations, prices, callback }, establishment, image, translationDtos, priceDtos]) =>
        this.uploadProductImage(image).pipe(
          mergeMap(imageURL =>
            this.productService.productV2ControllerUpdateProduct({ productId, updateProductDto: {
              ...updateProductDto,
              image: imageURL || updateProductDto.image,
            } }).pipe(
              mergeMap(product => this.saveProductTranslations$(
                establishment.enabledLanguages || [],
                translationDtos,
                translations,
                productId,
              ).pipe(
                mergeMap(translationEffects => this.saveProductPrices$(priceDtos, prices, productId).pipe(
                  mergeMap(priceEffects => [
                    ...translationEffects,
                    ...priceEffects,
                    productActions.updateProductSuccess({ product, callback }),
                  ]),
                )))),
              catchError((error: HttpErrorResponse) => of(productActions.updateProductFailure({ reason: error.error?.error }))),
            ),
          ),
        ),
      ),
    ),
  );

  public deleteProduct$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.deleteProduct),
      mergeMap(({ productId }) =>
        this.productService.productV2ControllerDeleteProduct({ productId }).pipe(
          map(() => productActions.deleteProductSuccess({ id: productId })),
          catchError((error: HttpErrorResponse) => of(productActions.deleteProductFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public getProductTranslations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.getProductTranslations),
      mergeMap(({ productId }) =>
        this.productService.productV2ControllerGetProductTranslations({ productId }).pipe(
          map(translations => productActions.getProductTranslationsSuccess({ productId, translations })),
          catchError((error: HttpErrorResponse) =>
            of(productActions.getProductTranslationsFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public createProductTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.createProductTranslation),
      mergeMap(({ productId, createTranslationDto }) =>
        this.productService.productV2ControllerCreateProductTranslations({ productId, createTranslationDto }).pipe(
          map(translation => productActions.createProductTranslationSuccess({ productId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(productActions.createProductTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public updateProductTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.updateProductTranslation),
      mergeMap(({ productId, translationId, createTranslationDto }) =>
        this.productService.productV2ControllerUpdateProductTranslations({ productId, translationId, createTranslationDto }).pipe(
          map(translation => productActions.updateProductTranslationSuccess({ productId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(productActions.updateProductTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public deleteProductTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(productActions.deleteProductTranslation),
      mergeMap(({ productId, translationId }) =>
        this.productService.productV2ControllerDeleteProductTranslations({ productId, translationId }).pipe(
          map(translation => productActions.deleteProductTranslationSuccess({ productId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(productActions.deleteProductTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public executeCallback$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        productActions.createProductSuccess,
        productActions.updateProductSuccess,
      ),
      filter(({ callback }) => !!callback),
      tap(({ callback }) => callback!()),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly productService: ProductsService,
    private readonly translateService: TranslateService,
    private readonly storage: Storage = inject(Storage),
  ) {}

  private uploadProductImage(value: string | null): Observable<string> {
    if (!value) return of("");

    return this.store.select(fromAuth.selectUser).pipe(
      filter<User | undefined>(isDefined),
      mergeMap(user => {
        const path = `${user!.id}/product/${Date.now()}`;
        const storageRef = ref(this.storage, path);

        return new Observable<string>(observer => {
          uploadString(storageRef, value, "data_url")
            .then(() => getDownloadURL(storageRef).then(url => observer.next(url)))
            .catch(error => observer.error(error));
        });
      }),
    );
  }

  private saveProductTranslations$(
    enabledLanguages: EstablishmentDto.EnabledLanguagesEnum[],
    translations: TranslationDto[],
    values: Record<EstablishmentDto.EnabledLanguagesEnum, Partial<TranslationDto>>,
    productId: number,
  ): Observable<Action[]> {
    const actions: Action[] = [];

    enabledLanguages.forEach(language => {
      const translation = translations.find(t => t.language === language);
      const value = values[language]?.value;
      const description = values[language]?.description;

      if (!translation && (value || description)) {
        return actions.push(productActions.createProductTranslation({
          productId,
          createTranslationDto: {
            language,
            productId,
            value: value!,
            description,
          },
        }));
      }

      if (translation && !value && !description) {
        return actions.push(productActions.deleteProductTranslation({
          productId,
          translationId: translation.id,
        }));
      }

      if (translation && (value !== translation.value || description !== translation.description)) {
        return actions.push(productActions.updateProductTranslation({
          productId,
          translationId: translation.id,
          createTranslationDto: {
            ...translation,
            value: value!,
            description,
          },
        }));
      }

      return;
    });

    return of(actions.filter(Boolean));
  }

  private saveProductPrices$(
    prices: PriceDto[],
    values: Record<PriceDto.ServingEnum, Partial<PriceDto>>,
    productId: number,
  ): Observable<Action[]> {
    const actions: Action[] = [];

    Object.values(PriceDto.ServingEnum).forEach(serving => {
      const price = prices.find(p => p.serving === serving);
      const value = values[serving]?.price;

      if (!price && value) {
        return actions.push(priceActions.createProductPrice({
          productId,
          createPriceDto: { productId, serving, price: value },
        }));
      }

      if (price && !value) {
        return actions.push(priceActions.deleteProductPrice({ productId, priceId: price.id! }));
      }

      if (price && (value !== price.price)) {
        return actions.push(priceActions.updateProductPrice({
          productId,
          priceId: price.id!,
          updatePriceDto: { productId, serving, price: value },
        }));
      }

      return;
    });

    return of(actions.filter(Boolean));
  }
}
