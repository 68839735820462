import * as Sentry from "@sentry/angular-ivy";
import { APP_ID, APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { appInititializerFactory } from "./services/init/app-initializer.factory";
import { Store } from "@ngrx/store";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule } from "@angular/material/icon";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { ShowWhenLoadingModule } from "@directives";
import { AuthInterceptor, LoaderInterceptor, VersionInterceptor } from "@interceptors";
import { ApiModule, Configuration, EstablishmentDto } from "@api";
import { StoreModule } from "@store";
import { I18nModule } from "@i18n";
import { APP_RELEASE, DEFAULT_LANGUAGE, MAP_LANGUAGE_ENUM_TO_ISO, environment } from "@constants";
import { RegisterSuccessModule } from "./dialogs/register-success/register-success.module";
import { AlertModule } from "./dialogs/alert/alert.module";
import { ResendDoiModule } from "./dialogs/resend-doi/resend-doi.module";
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from "@angular/material/progress-spinner";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { provideAuth, getAuth } from "@angular/fire/auth";
import { provideAnalytics, getAnalytics } from "@angular/fire/analytics";
import { provideStorage, getStorage } from "@angular/fire/storage";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import spanish from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import player, { LottiePlayer } from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { Router } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { CreateEstablishmentModule } from "./dialogs/create-establishment/create-establishment.module";

registerLocaleData(spanish);

export const setApiConfig = (): Configuration => new Configuration({ basePath: environment.baseApi });

export const playerFactory = (): LottiePlayer => player;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule,
    ApiModule.forRoot(setApiConfig),
    LottieModule.forRoot({ player: playerFactory }),
    I18nModule,
    ShowWhenLoadingModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatIconModule,

    // Dialogs
    AlertModule,
    ResendDoiModule,
    RegisterSuccessModule,
    CreateEstablishmentModule,

    // Firebase
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    provideStorage(() => getStorage()),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInititializerFactory, deps: [Store], multi: true },
    { provide: APP_INITIALIZER, useFactory: () => (): void => {}, deps: [Sentry.TraceService], multi: true },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService): string => translateService.currentLang
        ? MAP_LANGUAGE_ENUM_TO_ISO[translateService.currentLang.toUpperCase() as EstablishmentDto.EnabledLanguagesEnum]
        : MAP_LANGUAGE_ENUM_TO_ISO[DEFAULT_LANGUAGE],
    },
    { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, logErrors: true }) },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: DEFAULT_CURRENCY_CODE, useValue: "EUR" },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: APP_ID, useValue: "admin" },
    { provide: APP_RELEASE, useValue: environment.release },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "fill" } },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: { strokeWidth: 4 } },
    { provide: MAT_SELECT_CONFIG, useValue: { disableRipple: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
