import { PriceDto } from "@api";

export const PRICE_FEATURE_KEY = "price";

export interface Prices {
  [priceId: number]: PriceDto;
}

export interface PriceState {
  prices: Prices;
  loading: boolean;
}

export const initialState: PriceState = {
  prices: {},
  loading: false,
};

export const persistedKeys: (keyof PriceState)[] = ["prices"];
