import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppRoutes } from "@constants";
import { AuthGuard } from "@guards";

const routes: Routes = [
  {
    path: AppRoutes.Register,
    loadChildren: () => import("./pages/register/register.module").then(m => m.RegisterModule),
  },
  {
    path: AppRoutes.ConfirmEmail,
    loadChildren: () => import("./pages/confirm-email/confirm-email.module").then(m => m.ConfirmEmailModule),
  },
  {
    path: AppRoutes.Login,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginModule),
  },
  {
    path: AppRoutes.ForgotPassword,
    loadChildren: () => import("./pages/forgot-password/forgot-password.module").then(m => m.ForgotPasswordModule),
  },
  {
    path: AppRoutes.ResetPassword,
    loadChildren: () => import("./pages/reset-password/reset-password.module").then(m => m.ResetPasswordModule),
  },
  {
    path: AppRoutes.Main,
    loadChildren: () => import("./pages/main/main.module").then(m => m.MainModule),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
