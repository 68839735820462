import { MatDrawerMode } from "@angular/material/sidenav";
import { EstablishmentDto } from "@api";

export const APP_FEATURE_KEY = "app";

export interface AppState {
  language: EstablishmentDto.EnabledLanguagesEnum;
  loading: boolean;
  sidenavOpened: boolean;
  sidenavFolded: boolean;
  sidenavMode: MatDrawerMode;
}

export const initialState: AppState = {
  language: EstablishmentDto.EnabledLanguagesEnum.Spanish,
  loading: false,
  sidenavOpened: true,
  sidenavFolded: false,
  sidenavMode: "side",
};

export const persistedKeys: (keyof AppState)[] = ["language", "sidenavOpened", "sidenavFolded"];
