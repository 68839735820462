import { MemoizedSelector, createFeatureSelector, createSelector } from "@ngrx/store";
import { MENU_FEATURE_KEY, MenuState } from "./menu.state";
import { selectSelectedEstablishmentId } from "../establishment/establishment.selectors";
import { MenuCategoryDto, MenuCategoryProductDto, MenuDto, TimetableDto, TranslationDto } from "@api";
import { sortByOrder, sortByWeekday } from "@utils";

type MemoizedMenu = MemoizedSelector<object, MenuDto, (s1: MenuState) => MenuDto>;
type MemoizedMenuTranslations = MemoizedSelector<object, TranslationDto[], (s1: MenuState) => Record<number, TranslationDto>>;
type MemoizedMenuCategories = MemoizedSelector<object, MenuCategoryDto[], (s1: MenuState) => MenuCategoryDto[]>;
type MemoizedMenuCategoryProducts = MemoizedSelector<object, MenuCategoryProductDto[], (s1: MenuState, s2: number) => MenuCategoryProductDto[]>; // eslint-disable-line max-len
type MemoizedCategoryId = MemoizedSelector<object, number | undefined, (s1: MenuState, s2: number) => number | undefined>;
type MemoizedMenuCategoryId = MemoizedSelector<object, number, (s1: MenuState) => number>;
type MemoizedTimetables = MemoizedSelector<object, TimetableDto[], (s1: MenuState) => TimetableDto[]>;

const getState = createFeatureSelector<MenuState>(MENU_FEATURE_KEY);

export const selectLoading = createSelector(getState, state => state.loading);

export const selectSelectedEstablishmentMenus = createSelector(getState, selectSelectedEstablishmentId, (state, selectedEstablishmentId) =>
  [...(Object.values(state.menus).filter((menu: MenuDto) => menu.establishmentId === selectedEstablishmentId) || [])].sort(sortByOrder),
);

export const selectMenu = (menuId: number): MemoizedMenu => createSelector(getState, state => state.menus[menuId]);

export const selectMenuTranslations = (menuId: number): MemoizedMenuTranslations =>
  createSelector(getState, state => Object.values(state.translations[menuId] || {}));

export const selectMenuCategories = (menuId: number): MemoizedMenuCategories =>
  createSelector(getState, state => [...(state.categories[menuId] || [])].sort(sortByOrder),
  );

export const selectMenuTimetables = (menuId: number): MemoizedTimetables =>
  createSelector(getState, state => [...(state.timetables[menuId] || [])].sort(sortByWeekday));

export const selectSelectedMenuCategoryId = (menuId: number): MemoizedMenuCategoryId =>
  createSelector(getState, state => state.selectedMenuCategory[menuId]);

export const selectSelectedCategoryId = (menuId: number): MemoizedCategoryId =>
  createSelector(getState, selectSelectedMenuCategoryId(menuId), (state, selectedmenucategory) =>
    state.categories[menuId]?.find(mc => mc.id === selectedmenucategory)?.categoryId,
  );

export const selectCategoryIdFromMenuCategoryId = (menuId: number, menuCategoryId: number): MemoizedCategoryId =>
  createSelector(getState, state => state.categories[menuId]?.find(mc => mc.id === menuCategoryId)?.categoryId);

export const selectShowInactiveCategories = createSelector(getState, state => state.showInactiveCategories);

export const selectSelectedMenuCategoryProducts = (menuId: number): MemoizedMenuCategoryProducts =>
  createSelector(getState, selectSelectedMenuCategoryId(menuId), (state, selectedMenuCategory) =>
    [...(
      Object
        .values(state.products)
        .filter((menuCategoryProduct: MenuCategoryProductDto) => menuCategoryProduct.menuCategoryId === selectedMenuCategory,
        ) || []
    )].sort(sortByOrder),
  );
