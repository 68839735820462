import {
  CategoryDto,
  CategoryV2ControllerCreateCategoryRequestParams,
  CategoryV2ControllerCreateCategoryTranslationsRequestParams,
  CategoryV2ControllerDeleteCategoryRequestParams,
  CategoryV2ControllerDeleteCategoryTranslationsRequestParams,
  CategoryV2ControllerGetCategoryTranslationsRequestParams,
  CategoryV2ControllerUpdateCategoryRequestParams,
  CategoryV2ControllerUpdateCategoryTranslationsRequestParams,
  TranslationDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getCategories = createAction("@categories/get-categories");
export const getCategoriesSuccess = createAction("@categories/get-categories-success", props<{ categories: CategoryDto[] }>());
export const getCategoriesFailure = createAction("@categories/get-categories-failure", props<{ reason: string }>());

export const createCategory = createAction("@categories/create-category", props<CategoryV2ControllerCreateCategoryRequestParams & { callback?: () => void; menuId?: number }>());
export const createCategorySuccess = createAction("@categories/create-category-success", props<{ category: CategoryDto; callback?: () => void; menuId?: number }>());
export const createCategoryFailure = createAction("@categories/create-category-failure", props<{ reason: string }>());

export const updateCategory = createAction("@categories/update-category", props<CategoryV2ControllerUpdateCategoryRequestParams>());
export const updateCategorySuccess = createAction("@categories/update-category-success", props<{ category: CategoryDto }>());
export const updateCategoryFailure = createAction("@categories/update-category-failure", props<{ reason: string }>());

export const deleteCategory = createAction("@categories/delete-category", props<CategoryV2ControllerDeleteCategoryRequestParams>());
export const deleteCategorySuccess = createAction("@categories/delete-category-success", props<{ id: number }>());
export const deleteCategoryFailure = createAction("@categories/delete-category-failure", props<{ reason: string }>());

export const getCategoryTranslations = createAction("@categories/get-category-translations", props<CategoryV2ControllerGetCategoryTranslationsRequestParams>());
export const getCategoryTranslationsSuccess = createAction("@categories/get-category-translations-success", props<{ categoryId: number; translations: TranslationDto[] }>());
export const getCategoryTranslationsFailure = createAction("@categories/get-category-translations-failure", props<{ reason: string }>());

export const createCategoryTranslation = createAction("@categories/create-category-translation", props<CategoryV2ControllerCreateCategoryTranslationsRequestParams>());
export const createCategoryTranslationSuccess = createAction("@categories/create-category-translation-success", props<{ categoryId: number; translation: TranslationDto }>());
export const createCategoryTranslationFailure = createAction("@categories/create-category-translation-failure", props<{ reason: string }>());

export const updateCategoryTranslation = createAction("@categories/update-category-translation", props<CategoryV2ControllerUpdateCategoryTranslationsRequestParams>());
export const updateCategoryTranslationSuccess = createAction("@categories/update-category-translation-success", props<{ categoryId: number; translation: TranslationDto }>());
export const updateCategoryTranslationFailure = createAction("@categories/update-category-translation-failure", props<{ reason: string }>());

export const deleteCategoryTranslation = createAction("@categories/delete-category-translation", props<CategoryV2ControllerDeleteCategoryTranslationsRequestParams>());
export const deleteCategoryTranslationSuccess = createAction("@categories/delete-category-translation-success", props<{ categoryId: number; translation: TranslationDto }>());
export const deleteCategoryTranslationFailure = createAction("@categories/delete-category-translation-failure", props<{ reason: string }>());
