<h2 mat-dialog-title>{{ "auth.registerSuccess.title" | translate }}</h2>

<div mat-dialog-content>
    <h3>{{ "auth.registerSuccess.subtitle" | translate }}</h3>
    <p [innerHTML]="'auth.registerSuccess.message' | translate : { email: data.email }"></p>
</div>

<div align="end" mat-dialog-actions>
    <button color="primary" mat-dialog-close mat-raised-button>
        {{ "auth.registerSuccess.cta" | translate }}
    </button>
</div>
