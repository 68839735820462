import { Platform } from "@angular/cdk/platform";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Auth, signInAnonymously, signOut } from "@angular/fire/auth";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AuthService, PaymentsService, StatisticsService, User, UsersService } from "@api";
import { ALERT_DIALOG_MAX_WIDTH, ALERT_DIALOG_WIDTH, AppRoutes, DEFAULT_ERROR_MESSAGE, environment } from "@constants";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { appActions, authActions, RootState } from "@store";
import { CheckoutLocale, loadStripe } from "@stripe/stripe-js";
import { getHttpErrorMessage } from "@utils";
import { AlertComponent } from "apps/admin/src/app/dialogs/alert/alert.component";
import { RegisterSuccessComponent } from "apps/admin/src/app/dialogs/register-success/register-success.component";
import { ResendDoiComponent } from "apps/admin/src/app/dialogs/resend-doi/resend-doi.component";
import { catchError, delay, filter, map, mergeMap, Observable, of, tap } from "rxjs";

@Injectable()
export class AuthEffects {
  public login$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      mergeMap(({ signInDto, shouldRedirect }) =>
        this.authService.authenticationControllerLogin({ signInDto }).pipe(
          map(loginResponse => authActions.loginSuccess({ ...loginResponse, shouldRedirect })),
          catchError((error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.PreconditionFailed) {
              return of(authActions.loginPreconditionFailed({ email: signInDto.username }));
            }

            return of(authActions.loginFailure({ reason: "auth.login.failureMessage" }));
          }),
        ),
      ),
    ),
  );

  public loginSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess, authActions.confirmSuccess),
      filter((action: any) => action.shouldRedirect), // eslint-disable-line @typescript-eslint/no-explicit-any
      map(() => {
        if (this.platform.ANDROID || this.platform.IOS) this.store.dispatch(appActions.toggleSidenav({ opened: false }));
        this.router.navigate([AppRoutes.Main]);
        return authActions.loadUser();
      }),
    ),
  );

  public loginPreconditionFailed$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginPreconditionFailed),
      tap(({ email }) => {
        this.dialog.open(ResendDoiComponent, {
          width: ALERT_DIALOG_WIDTH,
          maxWidth: ALERT_DIALOG_MAX_WIDTH,
          data: { email },
        });
      }),
    ), { dispatch:false },
  );

  public register$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.register),
      mergeMap(({ signUpDto }) =>
        this.authService.authenticationControllerRegister({
          signUpDto: {
            ...signUpDto,
            subscription: signUpDto.subscription || User.SubscriptionEnum.Basic,
          },
        }).pipe(
          map(() => authActions.registerSuccess({ email: signUpDto.email })),
          catchError((error: HttpErrorResponse) => of(authActions.loginFailure({ reason: error.error.response?.message.toString() }))),
        ),
      ),
    ),
  );

  public registerSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.registerSuccess),
      tap(({ email }) => {
        const dialogRef = this.dialog.open(RegisterSuccessComponent, {
          width: ALERT_DIALOG_WIDTH,
          maxWidth: ALERT_DIALOG_MAX_WIDTH,
          data: { email },
        });
        dialogRef.afterClosed().subscribe(() => this.router.navigate([AppRoutes.Login]));
      }),
    ), { dispatch: false },
  );

  public confirm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.confirm),
      mergeMap(() =>
        this.authService.authenticationControllerConfirmEmail({}).pipe(
          mergeMap(tokens => [
            authActions.confirmSuccess(tokens),
            authActions.loadUser(),
          ]),
          catchError((error: HttpErrorResponse) => of(authActions.confirmFailure({ reason: error.error.response?.message.toString() }))),
        ),
      ),
    ),
  );

  public confirmSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.confirmSuccess),
      tap(() => this.router.navigate([AppRoutes.Main])),
    ), { dispatch: false },
  );

  public resendConfirmationEmail$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resendConfirmationEmail),
      mergeMap(({ resendConfirmationEmailDto }) =>
        this.authService.authenticationControllerResendConfirmationEmail({ resendConfirmationEmailDto }).pipe(
          map(() => authActions.resendConfirmationEmailSuccess({ email: resendConfirmationEmailDto.email })),
          catchError((error: HttpErrorResponse) => {
            if (error.error.response?.message.toString() === "EMAIL_ALREADY_CONFIRMED") {
              return of (authActions.resendConfirmationEmailSuccess({ email: resendConfirmationEmailDto.email }));
            }
            return of(authActions.resendConfirmationEmailFailure({ reason: error.error.response?.message.toString() }));
          }),
        ),
      ),
    ),
  );

  public resendConfirmationEmailSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resendConfirmationEmailSuccess),
      tap(() => {
        this.dialog.closeAll();

        this.dialog.open(AlertComponent, {
          width: ALERT_DIALOG_WIDTH,
          maxWidth: ALERT_DIALOG_MAX_WIDTH,
          data: {
            title: this.translateService.instant("auth.upgradeRequiredSuccess.title"),
            message: this.translateService.instant("auth.upgradeRequiredSuccess.message"),
            cta: this.translateService.instant("auth.upgradeRequiredSuccess.cta"),
          },
        });
      }),
    ), { dispatch: false },
  );

  public loginWithGoogle$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginWithGoogle),
      mergeMap(({ googleTokenDto }) =>
        this.authService.googleAuthenticationControllerAuthenticate({ googleTokenDto }).pipe(
          map(tokens => authActions.loginSuccess({ ...tokens, shouldRedirect: true })),
          catchError((error: HttpErrorResponse) => of(authActions.loginFailure({ reason: error.error.response?.message.toString() }))),
        ),
      ),
    ),
  );

  public forgotPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.forgotPassword),
      mergeMap(({ resetPasswordDto }) =>
        this.authService.authenticationControllerResetPassword({ resetPasswordDto }).pipe(
          map(() => authActions.forgotPasswordSuccess()),
          catchError((error: HttpErrorResponse) => {
            if (error.error.response?.message.toString() === "ACCOUNT_DOES_NOT_EXISTS") {
              return of (authActions.forgotPasswordSuccess());
            }
            return of(authActions.forgotPasswordFailure({ reason: error.error.response?.message.toString() }));
          }),
        ),
      ),
    ),
  );

  public forgotPasswordSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.forgotPasswordSuccess),
      tap(() => {
        this.dialog.open(AlertComponent, {
          width: ALERT_DIALOG_WIDTH,
          maxWidth: ALERT_DIALOG_MAX_WIDTH,
          data: {
            title: this.translateService.instant("auth.forgotPassword.title"),
            message: this.translateService.instant("auth.forgotPassword.message"),
            cta: this.translateService.instant("auth.forgotPassword.cta"),
          },
        });
      }),
    ), { dispatch:false },
  );

  public resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPassword),
      mergeMap(({ setNewPasswordDto, authorization }) =>
        this.authService.authenticationControllerSetNewPassword({ authorization, setNewPasswordDto }).pipe(
          map(tokens => authActions.resetPasswordSuccess(tokens)),
          catchError((error: HttpErrorResponse) =>
            of(authActions.resetPasswordFailure({ reason: error.error.message })),
          ),
        ),
      ),
    ),
  );

  public loadUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loadUser),
      mergeMap(() =>
        this.userService.userControllerFindOne().pipe(
          map(user => authActions.loadUserSuccess({ user })),
          catchError((error: HttpErrorResponse) => of(authActions.loadUserFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public loadUserSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loadUserSuccess),
      tap(({ user }) => {
        signInAnonymously(this.auth);

        if (user.language) this.store.dispatch(appActions.changeLanguage({ language: user.language }));
      }),
    ), { dispatch:false },
  );

  public updateUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.updateUser),
      mergeMap(({ id, user }) =>
        this.userService.userControllerUpdate({ id, user }).pipe(
          map(updatedUser => authActions.updateUserSuccess({ user: updatedUser })),
          catchError((error: HttpErrorResponse) => of(authActions.updateUserFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public changeUserLanguage$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changeUserLanguage),
      mergeMap(({ id, user }) =>
        this.userService.userControllerUpdate({ id, user }).pipe(
          map(updatedUser => authActions.changeUserLanguageSuccess({ user: updatedUser })),
          catchError((error: HttpErrorResponse) => of(authActions.changeUserLanguageFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public changeUserLanguageSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changeUserLanguageSuccess),
      map(({ user }) => appActions.changeLanguage({ language: user.language })),
    ),
  );

  public changeUserPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changeUserPassword),
      mergeMap(({ id, changePasswordDto, callback }) =>
        this.userService.userControllerChangePassword({ id, changePasswordDto }).pipe(
          map(updatedUser => authActions.changeUserPasswordSuccess({ user: updatedUser, callback })),
          catchError((error: HttpErrorResponse) => of(authActions.changeUserPasswordFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public changeUserPasswordSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changeUserPasswordSuccess),
      tap(() => this.store.dispatch(authActions.setSuccessMessage({ message: "settings.personal.passwordChanged" }))),
      delay(5000),
      tap(() => this.store.dispatch(authActions.cleanSuccessMessage())),
    ), { dispatch:false },
  );

  public changeSubscription$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changeSubscription),
      mergeMap(({ createCheckoutSessionRequest }) =>
        this.paymentService.paymentControllerCreateCheckoutSession({ createCheckoutSessionRequest }).pipe(
          map(session => authActions.changeSubscriptionSuccess({ session, locale: createCheckoutSessionRequest.locale as CheckoutLocale })),
          catchError((error: HttpErrorResponse) => of(authActions.changeSubscriptionFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public changeSubscriptionSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.changeSubscriptionSuccess),
      tap(async ({ session, locale }) => {
        const stripe = await loadStripe(environment.stripePublishableKey, { locale });
        if (!stripe) this.store.dispatch(authActions.changeSubscriptionFailure({ reason: "Failed to load stripe, please try again" }));

        if (session.type === "checkout") stripe!.redirectToCheckout({ sessionId: session.sessionId });
        else document.location.replace(session.sessionId);
      }),
    ), { dispatch:false },
  );

  public getDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getDashboard),
      mergeMap(() =>
        this.userService.userV2ControllerGetDashboard().pipe(
          map(dashboard => authActions.getDashboardSuccess({ dashboard })),
          catchError((error: HttpErrorResponse) => of(authActions.getDashboardFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public getSimpleStatistics$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.getSimpleStatistics),
      mergeMap(() =>
        this.statisticsService.statisticsControllerGetStatistics().pipe(
          map(statisticsSimple => authActions.getSimpleStatisticsSuccess({ statisticsSimple })),
          catchError((error: HttpErrorResponse) => of(authActions.getSimpleStatisticsFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public contact$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.contact),
      mergeMap(({ contactDto, callback }) =>
        this.userService.userV2ControllerContact({ contactDto }).pipe(
          map(() => authActions.contactSuccess({ callback })),
          catchError((error: HttpErrorResponse) => of(authActions.contactFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public contactSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.contactSuccess),
      tap(() => this.store.dispatch(authActions.setSuccessMessage({ message: "contact.sent" }))),
      delay(5000),
      tap(() => this.store.dispatch(authActions.cleanSuccessMessage())),
    ), { dispatch:false },
  );

  public deleteUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.deleteUser),
      mergeMap(({ id }) =>
        this.userService.userControllerRemove({ id }).pipe(
          mergeMap(() => [authActions.deleteUserSuccess(), authActions.logout()]),
          catchError((error: HttpErrorResponse) => of(authActions.deleteUserFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public logout$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.logout),
      tap(() => {
        signOut(this.auth);
        localStorage.clear();
        this.router.navigate([AppRoutes.Login]);
      }),
    ), { dispatch:false },
  );

  public showToast$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        authActions.registerFailure,
        authActions.confirmFailure,
        authActions.loginFailure,
        authActions.forgotPasswordFailure,
        authActions.resetPasswordFailure,
        authActions.loadUserFailure,
        authActions.updateUserFailure,
        authActions.changeUserPasswordFailure,
        authActions.changeSubscriptionFailure,
        authActions.getDashboardFailure,
        authActions.deleteUserFailure,
      ),
      tap(({ reason }) => this.snackBar.open(this.translateService.instant(reason || DEFAULT_ERROR_MESSAGE))),
    ), { dispatch: false },
  );

  public triggerCallback$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        authActions.changeUserPasswordSuccess,
        authActions.contactSuccess,
      ),
      filter(({ callback }) => !!callback),
      tap(({ callback }) => callback()),
    ), { dispatch:false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly authService: AuthService,
    private readonly userService: UsersService,
    private readonly paymentService: PaymentsService,
    private readonly translateService: TranslateService,
    private readonly statisticsService: StatisticsService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly auth: Auth = inject(Auth),
    public readonly platform: Platform,
  ) {}
}
