export const ICONS: string[] = [
  "arrow-left",
  "arrows-in-line-vertical",
  "arrows-out-line-vertical",
  "asterisk",
  "caret-down",
  "caret-left",
  "caret-right",
  "check-circle",
  "check",
  "contact",
  "copy",
  "dashboard",
  "dots-six-vertical",
  "download",
  "establishments",
  "eye-closed",
  "eye",
  "facebook",
  "globe",
  "information",
  "instagram",
  "magnifying-glass",
  "mail",
  "menu",
  "menus",
  "omm",
  "pencil",
  "phone",
  "pinterest",
  "plus-circle",
  "plus",
  "settings",
  "sign-out",
  "spotify",
  "trash",
  "twitter",
  "x",
];

export const ALLERGENS: string[] = [
  "celery",
  "crustaceans",
  "egg",
  "fish",
  "gluten",
  "lupin",
  "milk",
  "molluscs",
  "mustard",
  "peanuts",
  "sesame",
  "soybeans",
  "sulphurdioxide",
  "treenuts",
];
