import {
  EstablishmentControllerRemoveRequestParams,
  EstablishmentDto,
  EstablishmentV2ControllerCreateEstablishmentRequestParams,
  EstablishmentV2ControllerCreateEstablishmentTranslationsRequestParams,
  EstablishmentV2ControllerCreateOrUpdateEstablishmentTimetablesRequestParams,
  EstablishmentV2ControllerDeleteEstablishmentTranslationsRequestParams,
  EstablishmentV2ControllerGetEstablishmentTimetablesRequestParams,
  EstablishmentV2ControllerGetEstablishmentTranslationsRequestParams,
  EstablishmentV2ControllerUpdateEstablishmentRequestParams,
  EstablishmentV2ControllerUpdateEstablishmentTranslationsRequestParams,
  EstablishmentV2ControllerUseGoogleImageRequestParams,
  TimetableDto,
  TranslationDto,
} from "@api";
import { createAction, props } from "@ngrx/store";

export const getEstablishments = createAction("@establishments/get-establishments");
export const getEstablishmentsSuccess = createAction("@establishments/get-establishments-success", props<{ establishments: EstablishmentDto[] }>());
export const getEstablishmentsFailure = createAction("@establishments/get-establishments-failure", props<{ reason: string }>());

export const selectEstablishment = createAction("@establishment/select-establishment", props<{ id: number }>());

export const createEstablishment = createAction("@establishment/create-establishment", props<EstablishmentV2ControllerCreateEstablishmentRequestParams & { callback: () => void }>());
export const createEstablishmentSuccess = createAction("@establishment/create-establishment-success", props<{ establishment: EstablishmentDto; callback: () => void }>());
export const createEstablishmentFailure = createAction("@establishment/create-establishment-failure", props<{ reason: string }>());

export const updateEstablishment = createAction("@establishment/update-establishment", props<EstablishmentV2ControllerUpdateEstablishmentRequestParams>());
export const updateEstablishmentSuccess = createAction("@establishment/update-establishment-success", props<{ establishment: EstablishmentDto }>());
export const updateEstablishmentFailure = createAction("@establishment/update-establishment-failure", props<{ reason: string }>());

export const getEstablishmentTimetables = createAction("@establishment/get-establishment-timetables", props<EstablishmentV2ControllerGetEstablishmentTimetablesRequestParams>());
export const getEstablishmentTimetablesSuccess = createAction("@establishment/get-establishment-timetables-success", props<{ establishmentId: number; timetables: TimetableDto[] }>());
export const getEstablishmentTimetablesFailure = createAction("@establishment/get-establishment-timetables-failure", props<{ reason: string }>());

export const upsertEstablishmentTimetables = createAction("@establishment/upsert-establishment-timatebles", props<EstablishmentV2ControllerCreateOrUpdateEstablishmentTimetablesRequestParams>());
export const upsertEstablishmentTimetablesSuccess = createAction("@establishment/upsert-establishment-timatebles-success", props<{ establishmentId: number; timetables: TimetableDto[] }>());
export const upsertEstablishmentTimetablesFailure = createAction("@establishment/upsert-establishment-timatebles-failure", props<{ reason: string }>());

export const getEstablishmentTranslations = createAction("@establishment/get-establishment-translations", props<EstablishmentV2ControllerGetEstablishmentTranslationsRequestParams>());
export const getEstablishmentTranslationsSuccess = createAction("@establishment/get-establishment-translations-success", props<{ establishmentId: number; translations: TranslationDto[] }>());
export const getEstablishmentTranslationsFailure = createAction("@establishment/get-establishment-translations-failure", props<{ reason: string }>());

export const createEstablishmentTranslation = createAction("@establishment/create-establishment-translation", props<EstablishmentV2ControllerCreateEstablishmentTranslationsRequestParams>());
export const createEstablishmentTranslationSuccess = createAction("@establishment/create-establishment-translation-success", props<{ establishmentId: number; translation: TranslationDto }>());
export const createEstablishmentTranslationFailure = createAction("@establishment/create-establishment-translation-failure", props<{ reason: string }>());

export const updateEstablishmentTranslation = createAction("@establishment/update-establishment-translation", props<EstablishmentV2ControllerUpdateEstablishmentTranslationsRequestParams>());
export const updateEstablishmentTranslationSuccess = createAction("@establishment/update-establishment-translation-success", props<{ establishmentId: number; translation: TranslationDto }>());
export const updateEstablishmentTranslationFailure = createAction("@establishment/update-establishment-translation-failure", props<{ reason: string }>());

export const deleteEstablishmentTranslation = createAction("@establishment/delete-establishment-translation", props<EstablishmentV2ControllerDeleteEstablishmentTranslationsRequestParams>());
export const deleteEstablishmentTranslationSuccess = createAction("@establishment/delete-establishment-translation-success", props<{ establishmentId: number; translation: TranslationDto }>());
export const deleteEstablishmentTranslationFailure = createAction("@establishment/delete-establishment-translation-failure", props<{ reason: string }>());

export const deleteEstablishment = createAction("@establishment/delete-establishment", props<EstablishmentControllerRemoveRequestParams>());
export const deleteEstablishmentSuccess = createAction("@establishment/delete-establishment-success", props<EstablishmentControllerRemoveRequestParams>());
export const deleteEstablishmentFailure = createAction("@establishment/delete-establishment-failure", props<{ reason: string }>());

export const storeTemporaryLogo = createAction("@establishment/store-temporary-logo", props<{ dataURL: string }>());
export const removeTemporaryLogo = createAction("@establishment/remove-temporary-logo");
export const removeLogo = createAction("@establishment/remove-logo", props<{ url: string }>());

export const storeTemporaryImage = createAction("@establishment/store-temporary-image", props<{ dataURL: string }>());
export const removeTemporaryImage = createAction("@establishment/remove-temporary-image");
export const removeImage = createAction("@establishment/remove-image", props<{ url: string }>());

export const storeGooglePlace = createAction("@establishment/store-google-place-id", props<{ place: google.maps.places.PlaceResult }>());
export const removeGooglePlace = createAction("@establishment/remove-google-place-id");

export const useGoogleImage = createAction("@establishment/use-google-image", props<EstablishmentV2ControllerUseGoogleImageRequestParams>());
export const useGoogleImageSuccess = createAction("@establishment/use-google-image-success", props<{ establishment: EstablishmentDto }>());
export const useGoogleImageFailure = createAction("@establishment/use-google-image-failure", props<{ reason: string }>());
