import { Action, createReducer, on } from "@ngrx/store";
import { authActions } from "@store";
import { AuthState, initialState } from "./auth.state";

const authReducer = createReducer(
  initialState,

  on(
    authActions.register,
    authActions.login,
    authActions.resendConfirmationEmail,
    authActions.forgotPassword,
    authActions.loadUser,
    authActions.updateUser,
    authActions.getDashboard,
    authActions.getSimpleStatistics,
    authActions.contact,
    authActions.deleteUser,
    state => ({ ...state, loading: { ...state.loading, user: true } }),
  ),

  on(
    authActions.changeUserPassword,
    state => ({ ...state, loading: { ...state.loading, changePassword: true } }),
  ),

  on(
    authActions.changeUserLanguage,
    state => ({ ...state, loading: { ...state.loading, changeLanguage: true } }),
  ),

  on(authActions.setSuccessMessage, (state, { message }) => ({ ...state, successMessage: message })),

  on(authActions.cleanSuccessMessage, (state) => ({ ...state, successMessage: null })),

  on(authActions.confirm, (state, { token }) => ({
    ...state,
    accessToken: token,
    loading: {
      ...state.loading,
      user: true,
    },
  })),

  on(authActions.confirmSuccess, (state, { accessToken, refreshToken }) => ({
    ...state,
    accessToken,
    refreshToken,
    loading: {
      ...state.loading,
      user: true,
    },
  })),

  on(
    authActions.loginSuccess,
    authActions.resetPasswordSuccess,
    (state, { accessToken, refreshToken }) => ({
      ...state,
      accessToken,
      refreshToken,
      loading: {
        ...state.loading,
        user: true,
      } }),
  ),

  on(authActions.storeTokens, (state, { accessToken, refreshToken }) => ({
    ...state,
    accessToken: accessToken || state.accessToken,
    refreshToken: refreshToken || state.refreshToken,
  })),

  on(
    authActions.loadUserSuccess,
    authActions.updateUserSuccess,
    (state, { user }) => ({ ...state, user, loading: { ...state.loading, user: false } }),
  ),

  on(
    authActions.changeUserPasswordSuccess,
    (state, { user }) => ({ ...state, user, loading: { ...state.loading, changePassword: false } }),
  ),

  on(
    authActions.changeUserLanguageSuccess,
    (state, { user }) => ({ ...state, user, loading: { ...state.loading, changeLanguage: false } }),
  ),

  on(authActions.getDashboardSuccess, (state, { dashboard }) => ({
    ...state,
    dashboard,
    loading: {
      ...state.loading,
      user: false,
    },
  })),

  on(authActions.getSimpleStatisticsSuccess, (state, { statisticsSimple })=> ({
    ...state,
    statisticsSimple,
    loading: {
      ...state.loading,
      user: false,
    },
  })),

  on(
    authActions.registerSuccess,
    authActions.registerFailure,
    authActions.loginFailure,
    authActions.loginPreconditionFailed,
    authActions.forgotPasswordSuccess,
    authActions.forgotPasswordFailure,
    authActions.resetPasswordFailure,
    authActions.loadUserFailure,
    authActions.confirmFailure,
    authActions.resendConfirmationEmailSuccess,
    authActions.resendConfirmationEmailFailure,
    authActions.updateUserFailure,
    authActions.getDashboardFailure,
    authActions.getSimpleStatisticsFailure,
    authActions.contactSuccess,
    authActions.contactFailure,
    authActions.deleteUserFailure,
    state => ({ ...state, loading: { ...state.loading, user: false } }),
  ),

  on(
    authActions.changeUserPasswordFailure,
    state => ({ ...state, loading: { ...state.loading, changePassword: false } }),
  ),

  on(
    authActions.changeUserLanguageFailure,
    state => ({ ...state, loading: { ...state.loading, changeLanguage: false } }),
  ),

  on(
    authActions.deleteUserSuccess,
    authActions.logout,
    () => initialState,
  ),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: AuthState | undefined, action: Action) => authReducer(state, action);
