import { createAction, props } from "@ngrx/store";
import { AuthenticationControllerLoginRequestParams,
  AuthenticationControllerRegisterRequestParams,
  AuthenticationControllerResendConfirmationEmailRequestParams,
  AuthenticationControllerResetPasswordRequestParams,
  AuthenticationControllerSetNewPasswordRequestParams,
  CreateCheckoutSessionResponse,
  DashboardDto,
  GoogleAuthenticationControllerAuthenticateRequestParams,
  LoginResponseDto,
  PaymentControllerCreateCheckoutSessionRequestParams,
  ResendConfirmationEmailDto,
  StatisticsSimpleDto,
  User,
  UserControllerChangePasswordRequestParams,
  UserControllerRemoveRequestParams,
  UserControllerUpdateRequestParams,
  UserV2ControllerContactRequestParams,
} from "@api";
import { CheckoutLocale } from "@stripe/stripe-js";

export const storeTokens = createAction("@auth/store-tokens", props<Partial<LoginResponseDto>>());

export const login = createAction("@auth/login", props<AuthenticationControllerLoginRequestParams & { shouldRedirect: boolean }>());
export const loginSuccess = createAction("@auth/login-success", props<LoginResponseDto & { shouldRedirect: boolean }>());
export const loginPreconditionFailed = createAction("@auth/login-precondition-failed", props<{ email: string }>());
export const loginFailure = createAction("@auth/login-failure", props<{ reason: string }>());

export const register = createAction("@auth/register", props<AuthenticationControllerRegisterRequestParams>());
export const registerSuccess = createAction("@auth/register-success", props<{ email: string }>());
export const registerFailure = createAction("@auth/register-failure", props<{ reason: string }>());

export const confirm = createAction("@auth/confirm", props<{ token: string }>());
export const confirmSuccess = createAction("@auth/confirm-success", props<LoginResponseDto>());
export const confirmFailure = createAction("@auth/confirm-failure", props<{ reason: string }>());

export const resendConfirmationEmail = createAction("@auth/resend-confirmation-email", props<AuthenticationControllerResendConfirmationEmailRequestParams>());
export const resendConfirmationEmailSuccess = createAction("@auth/resend-confirmation-email-success", props<ResendConfirmationEmailDto>());
export const resendConfirmationEmailFailure = createAction("@auth/resend-confirmation-email-failure", props<{ reason: string }>());

export const forgotPassword = createAction("@auth/forgot-password", props<AuthenticationControllerResetPasswordRequestParams>());
export const forgotPasswordSuccess = createAction("@auth/forgot-password-success");
export const forgotPasswordFailure = createAction("@auth/forgot-password-failure", props<{ reason: string }>());

export const resetPassword = createAction("@auth/reset-password", props<AuthenticationControllerSetNewPasswordRequestParams>());
export const resetPasswordSuccess = createAction("@auth/reset-password-success", props<LoginResponseDto>());
export const resetPasswordFailure = createAction("@auth/reset-password-failure", props<{ reason: string }>());

export const loadUser = createAction("@auth/load-user");
export const loadUserSuccess = createAction("@auth/load-user-success", props<{ user: User }>());
export const loadUserFailure = createAction("@auth/load-user-failure", props<{ reason: string }>());

export const loginWithGoogle = createAction("@auth/login-with-google", props<GoogleAuthenticationControllerAuthenticateRequestParams>());

export const updateUser = createAction("@auth/update-user", props<UserControllerUpdateRequestParams>());
export const updateUserSuccess = createAction("@auth/update-user-success", props<{ user: User }>());
export const updateUserFailure = createAction("@auth/update-user-failure", props<{ reason: string }>());

export const changeUserLanguage = createAction("@auth/change-user-language", props<UserControllerUpdateRequestParams>());
export const changeUserLanguageSuccess = createAction("@auth/change-user-language-success", props<{ user: User }>());
export const changeUserLanguageFailure = createAction("@auth/change-user-language-failure", props<{ reason: string }>());

export const changeUserPassword = createAction("@auth/change-user-password", props<UserControllerChangePasswordRequestParams & { callback: () => void }>());
export const changeUserPasswordSuccess = createAction("@auth/change-user-password-success", props<{ user: User; callback: () => void }>());
export const changeUserPasswordFailure = createAction("@auth/change-user-password-failure", props<{ reason: string }>());

export const setSuccessMessage = createAction("@auth/set-success-message", props<{ message: string }>());
export const cleanSuccessMessage = createAction("@auth/clean-success-message");

export const deleteUser = createAction("@auth/delete-user", props<UserControllerRemoveRequestParams>());
export const deleteUserSuccess = createAction("@auth/delete-user-success");
export const deleteUserFailure = createAction("@auth/delete-user-failure", props<{ reason: string }>());

export const changeSubscription = createAction("@auth/change-subscription", props<PaymentControllerCreateCheckoutSessionRequestParams>());
export const changeSubscriptionSuccess = createAction("@auth/change-subscription-success", props<{ session: CreateCheckoutSessionResponse; locale: CheckoutLocale }>());
export const changeSubscriptionFailure = createAction("@auth/change-subscription-failure", props<{ reason: string }>());

export const getDashboard = createAction("@auth/get-dashboard");
export const getDashboardSuccess = createAction("@auth/get-dashboard-success", props<{ dashboard: DashboardDto }>());
export const getDashboardFailure = createAction("@auth/get-dashboard-failure", props<{ reason: string }>());

export const getSimpleStatistics = createAction("@auth/get-simple-statistics");
export const getSimpleStatisticsSuccess = createAction("@auth/get-simple-statistics-success", props<{ statisticsSimple: StatisticsSimpleDto }>());
export const getSimpleStatisticsFailure = createAction("@auth/get-simple-statistics-failure", props<{ reason: string }>());

export const contact = createAction("@auth/contact", props<UserV2ControllerContactRequestParams & { callback: () => void }>());
export const contactSuccess = createAction("@auth/contact-success", props<{ callback: () => void }>());
export const contactFailure = createAction("@auth/contact-failure", props<{ reason: string }>());

export const logout = createAction("@auth/logout");
