/* eslint-disable @typescript-eslint/indent,@typescript-eslint/ban-types,@typescript-eslint/no-explicit-any */
import { DEFAULT_LANGUAGE } from "@constants";
import { ActionReducer, INIT } from "@ngrx/store";
import { authActions, initialRootState } from "@store";

export const logoutMetaReducer = <State extends {}>(reducer: ActionReducer<State>): ActionReducer<State> => (state, action): any =>
    action.type === authActions.logout.type
        ? reducer({
            ...initialRootState,
            app: {
                ...initialRootState.app,
                language: (state as any)?.app?.language || DEFAULT_LANGUAGE,
                sidenavMode: (state as any)?.app?.sidenavMode || "side",
                sidenavFolded: (state as any)?.app?.sidenavFolded || false,
            },
        } as any, { type: INIT })
        : reducer(state, action);
