import { Action, createReducer, on } from "@ngrx/store";
import { authActions, establishmentActions } from "@store";
import { EstablishmentState, Establishments, initialState } from "./establishment.state";
import { TranslationDto } from "@api";

const establishmentReducer = createReducer(
  initialState,

  on(
    establishmentActions.getEstablishments,
    establishmentActions.getEstablishmentTimetables,
    establishmentActions.getEstablishmentTranslations,
    establishmentActions.createEstablishment,
    establishmentActions.createEstablishmentTranslation,
    establishmentActions.updateEstablishment,
    establishmentActions.useGoogleImage,
    establishmentActions.updateEstablishmentTranslation,
    establishmentActions.upsertEstablishmentTimetables,
    establishmentActions.deleteEstablishmentTranslation,
    establishmentActions.deleteEstablishment,
    state => ({ ...state, loading: true }),
  ),

  on(establishmentActions.getEstablishmentsSuccess, (state, { establishments }) => ({
    ...state,
    establishments: establishments.reduce((result, establishment) => {
      result[establishment.id!] = establishment;
      return result;
    }, {} as Establishments),
    loading: false,
  })),

  on(
    establishmentActions.createEstablishmentSuccess,
    establishmentActions.updateEstablishmentSuccess,
    establishmentActions.useGoogleImageSuccess,
    (state, { establishment }) => ({
      ...state,
      establishments: {
        ...state.establishments,
        [establishment.id!]: establishment,
      },
      selectedEstablishment: establishment.id!,
      temporarylogo: null,
      temporaryImage: null,
      loading: false,
    }),
  ),

  on(
    establishmentActions.getEstablishmentTimetablesSuccess,
    establishmentActions.upsertEstablishmentTimetablesSuccess,
    (state, { establishmentId, timetables }) => ({
      ...state,
      timetables: {
        ...state.timetables,
        [establishmentId]: timetables,
      },
      loading: false,
    }),
  ),

  on(establishmentActions.getEstablishmentTranslationsSuccess, (state, { establishmentId, translations }) => ({
    ...state,
    translations: {
      ...state.translations,
      [establishmentId]: translations.reduce((result, translation) => {
        result[translation.id] = translation;
        return result;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(
    establishmentActions.createEstablishmentTranslationSuccess,
    establishmentActions.updateEstablishmentTranslationSuccess,
    (state, { establishmentId, translation }) => ({
      ...state,
      translations: {
        ...state.translations,
        [establishmentId]: {
          ...state.translations[establishmentId],
          [translation.id]: translation,
        },
      },
      loading: false,
    }),
  ),

  on(establishmentActions.deleteEstablishmentTranslationSuccess, (state, { establishmentId, translation }) => ({
    ...state,
    translations: {
      ...state.translations,
      [establishmentId]: Object.values(state.translations[establishmentId]).reduce((translations, t) => {
        if (t.id !== translation.id) translations[t.id] = t;
        return translations;
      }, {} as Record<number, TranslationDto>),
    },
    loading: false,
  })),

  on(establishmentActions.selectEstablishment, (state, { id }) => ({ ...state, selectedEstablishment: id, googlePlace: null })),

  on(establishmentActions.deleteEstablishmentSuccess, (state, { id }) => {
    const establishments = Object.values(state.establishments).reduce((estabs, establishment) => {
      if (establishment.id !== id) estabs[establishment.id] = establishment;
      return estabs;
    }, {} as Establishments);

    return {
      ...state,
      establishments,
      selectedEstablishment: Object.keys(establishments).length ? establishments[Object.keys(establishments)[0]].id : 0,
      timetables: { ...state.timetables, [id]: [] },
      translations: { ...state.translations, [id]: {} },
      loading: false,
    };
  }),

  on(establishmentActions.storeTemporaryLogo, (state, { dataURL }) => ({ ...state, temporaryLogo: dataURL })),
  on(establishmentActions.removeTemporaryLogo, state => ({ ...state, temporaryLogo: null })),

  on(establishmentActions.storeTemporaryImage, (state, { dataURL }) => ({ ...state, temporaryImage: dataURL })),
  on(establishmentActions.removeTemporaryImage, state => ({ ...state, temporaryImage: null })),

  on(establishmentActions.storeGooglePlace, (state, { place }) => ({ ...state, googlePlace: place })),
  on(establishmentActions.removeGooglePlace, state => ({ ...state, googlePlace: null })),

  on(
    establishmentActions.getEstablishmentsFailure,
    establishmentActions.getEstablishmentTimetablesFailure,
    establishmentActions.getEstablishmentTranslationsFailure,
    establishmentActions.createEstablishmentFailure,
    establishmentActions.createEstablishmentTranslationFailure,
    establishmentActions.updateEstablishmentFailure,
    establishmentActions.useGoogleImageFailure,
    establishmentActions.updateEstablishmentTranslationFailure,
    establishmentActions.upsertEstablishmentTimetablesFailure,
    establishmentActions.deleteEstablishmentTranslationFailure,
    establishmentActions.deleteEstablishmentFailure,
    state => ({ ...state, loading: false }),
  ),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: EstablishmentState | undefined, action: Action) => establishmentReducer(state, action);
