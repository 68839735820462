import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Storage } from "@angular/fire/storage";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { CategoriesService } from "@api";
import { DEFAULT_ERROR_MESSAGE } from "@constants";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { RootState, categoryActions, fromApp, fromMenu, menuActions } from "@store";
import { getHttpErrorMessage } from "@utils";
import { Observable, catchError, filter, map, mergeMap, of, tap, withLatestFrom } from "rxjs";

@Injectable()
export class CategoryEffects {
  public getCategories$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.getCategories),
      mergeMap(() =>
        this.categoryService.categoryV2ControllerGetCategories().pipe(
          map(categories => categoryActions.getCategoriesSuccess({ categories })),
          catchError((error: HttpErrorResponse) => of(categoryActions.getCategoriesFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public createCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.createCategory),
      mergeMap(({ createCategoryDto, callback, menuId }) =>
        this.categoryService.categoryV2ControllerCreateCategory({ createCategoryDto }).pipe(
          map(category => categoryActions.createCategorySuccess({ category, callback, menuId })),
          catchError((error: HttpErrorResponse) => of(categoryActions.createCategoryFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public createCategorySuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.createCategorySuccess),
      withLatestFrom(this.store.select(fromApp.selectLanguage)),
      tap(([{ callback }]) => {
        if (callback) callback();
      }),
      mergeMap(([{ category }, language]) =>
        this.categoryService.categoryV2ControllerCreateCategoryTranslations({
          categoryId: category.id!,
          createTranslationDto: {
            language,
            value: category.name,
            categoryId: category.id!,
          },
        }).pipe(
          map(translation => categoryActions.createCategoryTranslationSuccess({ categoryId: category.id!, translation })),
          catchError((error: HttpErrorResponse) =>
            of(categoryActions.createCategoryTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public addCategoryToMenu$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.createCategorySuccess),
      filter(({ menuId }) => !!menuId),
      concatLatestFrom(({ menuId }) => this.store.select(fromMenu.selectMenuCategories(menuId!))),
      map(([{ category, menuId }, menuCategories]) =>
        menuActions.createMenuCategory({
          menuId: menuId!,
          categoryId: category.id!,
          createMenuCategoryDto: { order: menuCategories.length },
        }),
      ),
    ),
  );

  public editCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.updateCategory),
      mergeMap(({ categoryId, updateCategoryDto }) =>
        this.categoryService.categoryV2ControllerUpdateCategory({ categoryId, updateCategoryDto }).pipe(
          map(category => categoryActions.updateCategorySuccess({ category })),
          catchError((error: HttpErrorResponse) => of(categoryActions.updateCategoryFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public deleteCategory$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.deleteCategory),
      mergeMap(({ categoryId }) =>
        this.categoryService.categoryV2ControllerDeleteCategory({ categoryId }).pipe(
          map(() => categoryActions.deleteCategorySuccess({ id: categoryId })),
          catchError((error: HttpErrorResponse) => of(categoryActions.deleteCategoryFailure({ reason: error.error?.error }))),
        ),
      ),
    ),
  );

  public getCategoryTranslations$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.getCategoryTranslations),
      mergeMap(({ categoryId }) =>
        this.categoryService.categoryV2ControllerGetCategoryTranslations({ categoryId }).pipe(
          map(translations => categoryActions.getCategoryTranslationsSuccess({ categoryId, translations })),
          catchError((error: HttpErrorResponse) =>
            of(categoryActions.getCategoryTranslationsFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public createCategoryTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.createCategoryTranslation),
      mergeMap(({ categoryId, createTranslationDto }) =>
        this.categoryService.categoryV2ControllerCreateCategoryTranslations({ categoryId, createTranslationDto }).pipe(
          map(translation => categoryActions.createCategoryTranslationSuccess({ categoryId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(categoryActions.createCategoryTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public updateCategoryTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.updateCategoryTranslation),
      mergeMap(({ categoryId, translationId, createTranslationDto }) =>
        this.categoryService.categoryV2ControllerUpdateCategoryTranslations({ categoryId, translationId, createTranslationDto }).pipe(
          map(translation => categoryActions.updateCategoryTranslationSuccess({ categoryId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(categoryActions.updateCategoryTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public deleteCategoryTranslation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(categoryActions.deleteCategoryTranslation),
      mergeMap(({ categoryId, translationId }) =>
        this.categoryService.categoryV2ControllerDeleteCategoryTranslations({ categoryId, translationId }).pipe(
          map(translation => categoryActions.deleteCategoryTranslationSuccess({ categoryId, translation })),
          catchError((error: HttpErrorResponse) =>
            of(categoryActions.deleteCategoryTranslationFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public showError$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        categoryActions.getCategoriesFailure,
        categoryActions.createCategoryFailure,
        categoryActions.updateCategoryFailure,
        categoryActions.deleteCategoryFailure,
        categoryActions.getCategoryTranslationsFailure,
        categoryActions.createCategoryTranslationFailure,
        categoryActions.updateCategoryTranslationFailure,
        categoryActions.deleteCategoryTranslationFailure,
      ),
      tap(({ reason }) => this.snackBar.open(this.translateService.instant(reason || DEFAULT_ERROR_MESSAGE))),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly categoryService: CategoriesService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly storage: Storage = inject(Storage),
  ) {}
}
