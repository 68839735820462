import * as Sentry from "@sentry/angular-ivy";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";

Sentry.init({
  dsn: "https://53085d741eb8455a96d9c675eef509ad@o543353.ingest.sentry.io/5663425",
  integrations: [
    new Sentry.BrowserTracing({ routingInstrumentation: Sentry.routingInstrumentation }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/api.ohmymenu.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
