import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AppRoutes } from "@constants";
import { fromAuth, RootState } from "@store";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  constructor(private store: Store<RootState>, private router: Router) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store.select(fromAuth.selectAccessToken).pipe(
      switchMap(accessToken => {
        if (accessToken) return of(true);
        return of(this.router.parseUrl(AppRoutes.Login));
      }),
    );
  }
}
