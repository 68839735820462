import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PricesService, ProductsService } from "@api";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { RootState, priceActions } from "@store";
import { getHttpErrorMessage } from "@utils";
import { Observable, catchError, map, mergeMap, of } from "rxjs";

@Injectable()
export class PriceEffects {
  public getPrices$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(priceActions.getPrices),
      mergeMap(({ categoryId }) =>
        this.priceService.priceV2ControllerGetPrices({ categoryId }).pipe(
          map(prices => priceActions.getPricesSuccess({ prices })),
          catchError((error: HttpErrorResponse) => of(priceActions.getPricesFailure({ reason: getHttpErrorMessage(error) }))),
        ),
      ),
    ),
  );

  public createProductPrice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(priceActions.createProductPrice),
      mergeMap(({ productId, createPriceDto }) =>
        this.productService.productV2ControllerCreateProductPrices({ productId, createPriceDto }).pipe(
          map(price => priceActions.createProductPriceSuccess({ price })),
          catchError((error: HttpErrorResponse) =>
            of(priceActions.createProductPriceFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public updateProductPrice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(priceActions.updateProductPrice),
      mergeMap(({ productId, priceId, updatePriceDto }) =>
        this.productService.productV2ControllerUpdateProductPrice({ productId, priceId, updatePriceDto }).pipe(
          map(price => priceActions.updateProductPriceSuccess({ price })),
          catchError((error: HttpErrorResponse) =>
            of(priceActions.updateProductPriceFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  public deleteProductPrice$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(priceActions.deleteProductPrice),
      mergeMap(({ productId, priceId }) =>
        this.productService.productV2ControllerDeleteProductPrice({ productId, priceId }).pipe(
          map(price => priceActions.deleteProductPriceSuccess({ price })),
          catchError((error: HttpErrorResponse) =>
            of(priceActions.deleteProductPriceFailure({ reason: getHttpErrorMessage(error) })),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly priceService: PricesService,
    private readonly productService: ProductsService,
  ) {}
}
