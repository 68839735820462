/* eslint-disable @typescript-eslint/naming-convention,@typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { APP_ID, Inject, Injectable } from "@angular/core";
import { APP_RELEASE } from "@constants";
import { Store } from "@ngrx/store";
import { RootState, fromApp } from "@store";
import { Observable, first, switchMap } from "rxjs";

@Injectable({ providedIn: "root" })
export class VersionInterceptor implements HttpInterceptor {
  constructor(
    @Inject(APP_ID) private readonly origin: string,
    @Inject(APP_RELEASE) private readonly release: string,
    private readonly store: Store<RootState>,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(fromApp.selectLanguage).pipe(
      first(),
      switchMap(language => {
        req = req.clone({
          setHeaders: {
            "X-APP-RELEASE": this.release,
            "X-APP-ORIGIN": this.origin,
            "X-APP-VERSION": "2",
            "X-APP-LANGUAGE": language,
          },
        });

        return next.handle(req);
      }),
    );
  }
}
