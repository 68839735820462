import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { MAP_LANGUAGE_ISO_TO_ENUM, QueryParams } from "@constants";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { routerNavigatedAction } from "@ngrx/router-store";
import { Store } from "@ngrx/store";
import { appActions, fromRouter, RootState } from "@store";
import { Observable, tap, withLatestFrom } from "rxjs";

@Injectable()
export class RouterEffects {
  public routerNavigated$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(routerNavigatedAction),
      withLatestFrom(this.store.select(fromRouter.selectQueryParam(QueryParams.OverwriteLanguage))),
      tap(([{ payload }, overwriteLanguage]) => {
        // Change admin language when `overwrite_language` query string is present
        if (overwriteLanguage && Object.keys(MAP_LANGUAGE_ISO_TO_ENUM).includes(overwriteLanguage)) {
          this.store.dispatch(appActions.changeLanguage({ language: MAP_LANGUAGE_ISO_TO_ENUM[overwriteLanguage] }));
        }

        // Scroll mat-sidenav-content to top after each navigation
        const matSidenavContent = this.document.querySelector("mat-sidenav-content");
        matSidenavContent?.scrollTo(0, 0);
      }),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}
}
