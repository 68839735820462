<h2 mat-dialog-title>{{ data.title | translate }}</h2>

<div mat-dialog-content>
    <p [innerHTML]="data.message | translate : data.variables"></p>
</div>

<div align="end" mat-dialog-actions>
    <button color="primary" mat-dialog-close mat-raised-button>
        {{ data.cta ? (data.cta | translate) : "Ok" }}
    </button>
</div>
