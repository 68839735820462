import { ProductDto, TranslationDto } from "@api";

export const PRODUCT_FEATURE_KEY = "product";

export interface Products {
  [productId: number]: ProductDto;
}

export interface Translations {
  [productId: number]: Record<number, TranslationDto>;
}

export interface ProductState {
  products: Products;
  translations: Translations;
  temporaryImage: string | null;
  loading: boolean;
}

export const initialState: ProductState = {
  products: {},
  translations: {},
  temporaryImage: null,
  loading: false,
};

export const persistedKeys: (keyof ProductState)[] = ["translations"];
