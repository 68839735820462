import { Action, createReducer, on } from "@ngrx/store";
import { PriceState, Prices, initialState } from "./price.state";
import { priceActions } from "@store";

const priceReducer = createReducer(
  initialState,

  on(
    priceActions.getPrices,
    priceActions.createProductPrice,
    priceActions.updateProductPrice,
    priceActions.deleteProductPrice,
    state => ({ ...state, loading: true }),
  ),

  on(priceActions.getPricesSuccess, (state, { prices }) => ({
    ...state,
    prices: {
      ...state.prices,
      ...prices.reduce((result, price) => {
        result[price.id!] = price;
        return result;
      }, {} as Prices),
    },
    loading: false,
  })),

  on(
    priceActions.createProductPriceSuccess,
    priceActions.updateProductPriceSuccess,
    (state, { price }) => ({
      ...state,
      prices: {
        ...state.prices,
        [price.id!]: price,
      },
      loading: false,
    })),

  on(priceActions.deleteProductPriceSuccess, (state, { price: deleted }) => ({
    ...state,
    prices: Object.values(state.prices).reduce((prices, price) => {
      if (price.id !== deleted.id) prices[price.id] = price;
      return prices;
    }, {} as Prices),
    loading: false,
  })),

  on(
    priceActions.getPricesFailure,
    priceActions.createProductPriceFailure,
    priceActions.updateProductPriceFailure,
    priceActions.deleteProductPriceFailure,
    state => ({ ...state, loading: false }),
  ),
);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const reducer = (state: PriceState | undefined, action: Action) => priceReducer(state, action);
