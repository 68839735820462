import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShowWhenLoadingDirective } from "./show-when-loading.directive";

@NgModule({
  declarations: [ShowWhenLoadingDirective],
  imports: [CommonModule],
  exports: [ShowWhenLoadingDirective],
})
export class ShowWhenLoadingModule { }
