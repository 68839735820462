import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { authActions, fromAuth, RootState } from "@store";
import { Observable } from "rxjs";
import { ResendDoiData } from "./resend-doi-data.interface";

@Component({
  selector: "admin-resend-doi",
  templateUrl: "./resend-doi.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResendDoiComponent {
  public readonly loading$: Observable<boolean> = this.store.select(fromAuth.selectLoading("user"));
  public success: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ResendDoiData,
    private readonly store: Store<RootState>,
  ) {}

  public resendDoi(): void {
    this.store.dispatch(authActions.resendConfirmationEmail({ resendConfirmationEmailDto: this.data }));
  }
}
