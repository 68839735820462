import { EstablishmentDto, TimetableDto, TranslationDto } from "@api";

export const ESTABLISHMENT_FEATURE_KEY = "establishment";

export interface Establishments {
  [establishmentId: number]: EstablishmentDto;
}

export interface Timetables {
  [establishmentId: number]: TimetableDto[];
}

export interface Translations {
  [establishmentId: number]: Record<number, TranslationDto>;
}

export interface EstablishmentState {
  establishments: Establishments;
  timetables: Timetables;
  translations: Translations;
  selectedEstablishment: number;
  googlePlace: google.maps.places.PlaceResult | null;
  temporaryLogo: string | null;
  temporaryImage: string | null;
  loading: boolean;
}

export const initialState: EstablishmentState = {
  establishments: {},
  timetables: {},
  translations: {},
  selectedEstablishment: 0,
  temporaryLogo: null,
  temporaryImage: null,
  googlePlace: null,
  loading: false,
};

export const persistedKeys: (keyof EstablishmentState)[] = ["establishments", "timetables", "translations", "selectedEstablishment"];
