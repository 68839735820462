/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { appActions, RootState } from "@store";

@Injectable({ providedIn: "root" })
export class LoaderInterceptor implements HttpInterceptor {
  private requestsCounter: number;

  constructor(private store: Store<RootState>) {
    this.requestsCounter = 0;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(appActions.showLoader());
    this.requestsCounter++;

    return next.handle(req).pipe(
      finalize(() => {
        this.requestsCounter--;

        if (!this.requestsCounter) this.store.dispatch(appActions.hideLoader());
      }),
    );
  }
}
