import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RegisterSuccessData } from "./register-success-data.interface";

@Component({
  selector: "admin-register-success",
  templateUrl: "./register-success.component.html",
  styleUrls: ["./register-success.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterSuccessComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: RegisterSuccessData) {}
}
