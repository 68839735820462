import { DashboardDto, StatisticsSimpleDto, User } from "@api";

export const AUTH_FEATURE_KEY = "auth";

export interface AuthLoading {
  user: boolean;
  changePassword: boolean;
  changeLanguage: boolean;
}

export interface AuthState {
  user?: User;
  accessToken: string;
  refreshToken: string;
  dashboard: DashboardDto;
  statisticsSimple: StatisticsSimpleDto | null;
  successMessage: string | null;
  loading: AuthLoading;
}

export const initialState: AuthState = {
  user: undefined,
  accessToken: "",
  refreshToken: "",
  dashboard: {
    establishments: 0,
    menus: 0,
    categories: 0,
    products: 0,
    prices: 0,
    translations: 0,
  },
  statisticsSimple: null,
  successMessage: null,
  loading: {
    user: false,
    changePassword:false,
    changeLanguage: false,
  },
};

export const persistedKeys: (keyof AuthState)[] = ["user", "dashboard", "accessToken", "refreshToken"];
